import stores from 'stores'
import { ComponentController } from 'Common/components'
import { t } from 'i18n'

import { TimeService } from 'services'
import { ICreateRemoteStaffRequestInput } from 'stores/remoteWorkflowStore/models/createRemoteStaffRequestInput'
import { IRemoteStaffAvailabilityInput } from 'stores/remoteWorkflowStore/models/remoteStaffAvailabilityInput'
import { IRemoteWorkflowStore } from 'stores/remoteWorkflowStore/remoteWorkflowStore'
import {
  IRemoteStaffRequest,
  IWeScanAppointment,
  IWeScanRemoteStaffRequestDetails
} from 'stores/remoteWorkflowStore/models/remoteStaffRequest'
import { IWeScanRemoteStaffAvailability } from 'stores/remoteWorkflowStore/models/remoteStaffAvailabilityData'
import { RemoteRequestState } from 'stores/remoteWorkflowStore/enums/RemoteRequestState'
import { ICreateRemoteWeScanStaffFormData } from 'stores/remoteWorkflowStore/models/wescan/createRemoteWeScanStaffFormData'
import { IWeScanRemoteEquipmentProcedureData } from 'stores/remoteWorkflowStore/models/remoteEquipmentProcedureData'
import { WeScanServiceType } from 'stores/remoteWorkflowStore/enums/WeScanServiceType'
import { IViewRemoteWeScanStaffRequestInputFormDefinition } from 'stores/remoteWorkflowStore/models/wescan/viewRemoteWeScanStaffRequestInputFormDefinition'

// @ts-ignore
const remoteWorkflowStore: IRemoteWorkflowStore = stores.remoteWorkflowStore
const SLOT_DURATION_IN_MINUTES = 60
const TIME_FORMAT = 'hh:mm A'
const FULL_YEAR_DATE_FORMAT = 'YYYY-MM-DD'
const DATE_FORMAT = 'MMM DD'

export default class RemoteWeScanStaffRequestController extends ComponentController {
  get timeService(): TimeService {
    return this.props.timeService
  }

  get defaultState() {
    return {
      isLoading: false,
      isUpdating: false,
      serviceTypeError: false
    }
  }

  /**
   * If now is 02:36 AM, the nearest hour is 03:00 AM
   */
  isLessThanNearestHour(shiftDayStart: string, startTime: number) {
    let nearestCurrentDateTime = this.timeService.trueNow().set({ second: 0, millisecond: 0 })

    const currentDayStart = nearestCurrentDateTime.clone().startOf('day')
    const minutesFromStartOfDay = nearestCurrentDateTime.diff(currentDayStart, 'minutes')
    const minutesRemainder = minutesFromStartOfDay % SLOT_DURATION_IN_MINUTES

    if (minutesRemainder !== 0) {
      nearestCurrentDateTime = nearestCurrentDateTime.subtract(minutesRemainder, 'minutes')
    }

    const minimumAllowedStartsAt = nearestCurrentDateTime.add(SLOT_DURATION_IN_MINUTES, 'minutes')
    const dateMoment = this.timeService.timeMoment(shiftDayStart).startOf('day')
    const startTimeMoment = dateMoment.clone().add(startTime || 0, 'minutes')

    const isValid = startTimeMoment.isSameOrAfter(minimumAllowedStartsAt)

    return { minimumAllowedStartsAt: minimumAllowedStartsAt.format('MMM DD, hh:mm A'), isValid }
  }

  getRequestCancellationMessage(firstWeScanAppointmentStartsAt: string) {
    const isPast = this.timeService.isPastCurrentTime(firstWeScanAppointmentStartsAt)

    if (isPast) {
      return t('equipments.request_cannot_be_cancelled_message')
    }

    const lessThan30Days = this.timeService.getDaysDifference(firstWeScanAppointmentStartsAt) < 30

    if (lessThan30Days) {
      return t('equipments.request_cancellation_before_30_days_message')
    }

    return t('equipments.cancel_wescan_request_message')
  }

  getAppointmentCancellationMessage(firstWeScanAppointmentStartsAt: string, appointment: IWeScanAppointment) {
    const isPast = this.timeService.isPastCurrentTime(firstWeScanAppointmentStartsAt)

    if (isPast) {
      return t('equipments.request_cannot_be_cancelled_message')
    }

    const from = this.formatAsTime(appointment.startsAt)
    const to = this.formatAsTime(appointment.endsAt)

    const lessThan30Days = this.timeService.getDaysDifference(firstWeScanAppointmentStartsAt) < 30

    if (lessThan30Days) {
      return t('equipments.appointment_cancellation_before_30_days_message', { from, to })
    }

    return t('equipments.cancel_wescan_appointment_message', { from, to })
  }

  async getRemoteServicesTypesForEquipment(equipmentId: string) {
    this.setState({ isLoading: true, serviceTypeError: false })

    let serviceTypes: WeScanServiceType[] = []

    try {
      const remoteWorkflowServiceTypes = await remoteWorkflowStore.getRemoteServicesTypesForEquipment(equipmentId)
      serviceTypes = remoteWorkflowServiceTypes[0]?.data?.wescan || []
    } catch (e) {
      console.error('Error getting remote services types for equipment:', equipmentId, e)
      this.setState({ serviceTypeError: true })
    } finally {
      this.setState({ isLoading: false })
    }

    return serviceTypes
  }

  async getProceduresAndSkillsForServiceType(equipmentId: string, weScanServiceType: string) {
    this.setState({ isLoading: true })

    let proceduresAndSkills: IWeScanRemoteEquipmentProcedureData | undefined = void 0

    try {
      const result = await remoteWorkflowStore.getProceduresAndSkillsForServiceType(equipmentId, weScanServiceType)
      proceduresAndSkills = result[0]?.data?.wescan
    } catch (e) {
      console.error('Error getting procedures and skills for service type:', weScanServiceType, e)
    } finally {
      this.setState({ isLoading: false })
    }

    return proceduresAndSkills!
  }

  async getRemoteStaffAvailabilities(
    equipmentId: string,
    timeService: TimeService,
    shiftDayStart: string,
    form: ICreateRemoteWeScanStaffFormData
  ) {
    const { serviceType, procedures, skillLevel, startTime, endTime } = form

    if (!serviceType) {
      return []
    }

    const dateMoment = timeService.timeMoment(shiftDayStart).startOf('day')
    const dateString = dateMoment.format('YYYY-MM-DD')
    const startTimeMoment = dateMoment.clone().add(startTime || 0, 'minutes')
    const endTimeMoment = dateMoment.clone().add(endTime || 0, 'minutes')
    const startTimeString = startTimeMoment.format('HH:mm')
    const endTimeString = endTimeMoment.format('HH:mm')

    const data: IRemoteStaffAvailabilityInput = {
      weScanServiceType: serviceType,
      weScanProcedures: [procedures],
      date: dateString,
      startTime: startTimeString,
      endTime: endTimeString
    }

    if (skillLevel) {
      data.weScanSkillLevel = skillLevel
    }

    this.setState({ isLoading: true })

    let staffAvailabilities: IWeScanRemoteStaffAvailability[] = []

    try {
      const result = await remoteWorkflowStore.getRemoteStaffAvailabilities(equipmentId, data)
      staffAvailabilities = result[0]?.data?.wescan || []
    } catch (e) {
      console.error('Error getting remote staff availabilities for equipment:', equipmentId, e)
    } finally {
      this.setState({ isLoading: false })
    }

    return staffAvailabilities
  }

  _getSelectedSlots(selectedAvailabilityIndexes: number[], availabilities: IWeScanRemoteStaffAvailability[]) {
    return selectedAvailabilityIndexes.map((index: number) => {
      const { startsAt } = availabilities[index]

      return {
        startsAt
      }
    })
  }

  async createRemoteStaffRequest(
    shiftDayId: string,
    formData: ICreateRemoteWeScanStaffFormData,
    availabilities: IWeScanRemoteStaffAvailability[]
  ) {
    const { serviceType, procedures, skillLevel, radiologist, techAide } = formData

    if (!serviceType || !procedures || !formData.availabilities?.length) {
      return
    }

    const requestBody: ICreateRemoteStaffRequestInput = {
      wescan: {
        requestorPhone: formData.requestor.phone,
        serviceType,
        procedures: [procedures],
        skillLevel: Number(skillLevel),
        radiologist,
        techAide,
        appointments: this._getSelectedSlots(formData.availabilities, availabilities)
      }
    }

    await remoteWorkflowStore.createRemoteStaffRequest(shiftDayId, requestBody)
  }

  async getRemoteStaffRequests(shiftDayId: string) {
    return remoteWorkflowStore.getRemoteStaffRequests(shiftDayId)
  }

  async cancelRemoteStaffRequest(request: IRemoteStaffRequest) {
    return remoteWorkflowStore.cancelRemoteStaffRequest(request.id)
  }

  async cancelAppointment(request: IRemoteStaffRequest, appointmentId: string) {
    const body = { wescan: { appointmentIds: [appointmentId] } }
    return remoteWorkflowStore.cancelRemoteStaffRequest(request.id, body)
  }

  canRemoteWeScanRequestBeCancelled(request: IRemoteStaffRequest) {
    const state = request.state
    return state === RemoteRequestState.Open || state === RemoteRequestState.WorkInProgress
  }

  isRequestPastCurrentTime(request: IRemoteStaffRequest) {
    const firstWeScanAppointmentStartsAt = request.requestDetails.wescan!.appointments[0].startsAt
    return this.timeService.isPastCurrentTime(firstWeScanAppointmentStartsAt)
  }

  getAppointmentSlotText(appointments: IWeScanAppointment[]) {
    const numberOfSlots = appointments.length

    if (numberOfSlots === 0) {
      return ''
    }

    const firstAppointment = appointments[0]
    const lastAppointment = appointments[numberOfSlots - 1]

    const firstAppointmentFullDate = this.format(firstAppointment.startsAt)
    const lastAppointmentFullDate = this.format(lastAppointment.endsAt)
    const formattedStartsAt = this.formatAsTime(firstAppointment.startsAt)
    const formattedEndsAt = this.formatAsTime(lastAppointment.endsAt)
    const readableStartDate = this.formatAsDate(firstAppointment.startsAt)
    const readableEndDate = this.formatAsDate(lastAppointment.endsAt)

    if (firstAppointmentFullDate !== lastAppointmentFullDate) {
      return `${numberOfSlots} slot(s) from ${readableStartDate}, ${formattedStartsAt} to ${readableEndDate}, ${formattedEndsAt}`
    }

    return `${numberOfSlots} slot(s) from ${readableStartDate}, ${formattedStartsAt} to ${formattedEndsAt}`
  }

  format(date: string, format: string = FULL_YEAR_DATE_FORMAT) {
    const facilityTime = this.timeService.timeMoment(date)
    return this.timeService.format(facilityTime, format)
  }

  formatAsTime(date: string) {
    return this.format(date, TIME_FORMAT)
  }

  formatAsDate(date: string) {
    return this.format(date, DATE_FORMAT)
  }

  formatAsDateTime(date: string) {
    return this.format(date, `${DATE_FORMAT}, ${TIME_FORMAT}`)
  }

  getSkillLevelString(skillLevel: number) {
    switch (skillLevel) {
      case 3:
        return 'Intermediate'
      case 4:
        return 'Advanced'
      case 5:
        return 'Expert'
      default:
        return String(skillLevel)
    }
  }

  setViewDetailsFormDefinitionValues(
    formDefinition: IViewRemoteWeScanStaffRequestInputFormDefinition,
    requestDetails: IWeScanRemoteStaffRequestDetails,
    equipmentName: string
  ) {
    const { appointments, requestor, radiologist, techAide, serviceType, procedures, skillLevel } = requestDetails

    const firstWeScanAppointmentStartsAt = appointments[0].startsAt
    const lastWeScanAppointmentEndsAt = appointments[appointments.length - 1].endsAt

    // Tab 1
    formDefinition.properties.equipmentName.value = equipmentName
    formDefinition.properties.startTime.value = this.formatAsDateTime(firstWeScanAppointmentStartsAt) as any
    formDefinition.properties.endTime.value = this.formatAsDateTime(lastWeScanAppointmentEndsAt) as any
    formDefinition.properties.serviceType.value = serviceType
    formDefinition.properties.procedures.value = procedures.join(', ')
    formDefinition.properties.skillLevel.value = this.getSkillLevelString(skillLevel)

    // Tab 2
    formDefinition.properties.availabilities.title = 'Schedule Slots'
    formDefinition.properties.availabilities.enum = appointments

    // Tab 3
    formDefinition.properties.requestor.value = requestor
    formDefinition.properties.radiologist.value = radiologist
    formDefinition.properties.techAide.value = techAide
  }
}
