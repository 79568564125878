// @ts-ignore
import Form from '@humanics/he-react-common/lib/admin/components/Form'

import { t } from 'i18n'
import { cloneDeep } from 'lodash'
import { withAppContext } from 'AppContext'
import { Modal } from 'Common/components'
import WarningModal from './WarningModal'
import { useEffect, useRef, useState } from 'react'
import inputDefinition from '../createRemoteStaffRequestFormDefinition'
import RemoteWeScanStaffRequestController from '../RemoteWeScanStaffRequestController'

import { IWeScanRemoteStaffAvailability } from 'stores/remoteWorkflowStore/models/remoteStaffAvailabilityData'
import { ICreateRemoteWeScanStaffFormData } from 'stores/remoteWorkflowStore/models/wescan/createRemoteWeScanStaffFormData'
import {
  IWeScanLevel,
  IWeScanProcedure,
  IWeScanRemoteEquipmentProcedureData
} from 'stores/remoteWorkflowStore/models/remoteEquipmentProcedureData'
import { TimeService } from 'services'
import { WeScanServiceType } from 'stores/remoteWorkflowStore/enums/WeScanServiceType'

const SLOT_DURATION_IN_MINUTES = 60

function validateStartTime(controller: RemoteWeScanStaffRequestController, startDate: string, startTime: number) {
  const { isValid, minimumAllowedStartsAt } = controller.isLessThanNearestHour(startDate, startTime)

  if (!isValid) {
    return `Minimum allowed start time to check for availabilities is ${minimumAllowedStartsAt}`
  }

  return null
}

interface ICreateRemoteWeScanStaffRequestProps {
  appState: any
  openShift: any
  timeService: TimeService
  onCreated: () => void
  onClose: () => void
}

function CreateRemoteWeScanStaffRequest(props: ICreateRemoteWeScanStaffRequestProps) {
  const { appState, onCreated, timeService, openShift } = props
  const thisRef = useRef({ props })
  const remoteStaffRequestFormInputDefinition = cloneDeep(inputDefinition)

  const onClose = () => {
    props.onClose()
  }

  const controllerRef = useRef(new RemoteWeScanStaffRequestController(thisRef.current))
  const controller = controllerRef.current

  const equipmentName = openShift.get('shiftName')
  const equipmentId = openShift.get('shiftId')
  const shiftStartsAt = openShift.get('shiftStartsAt')
  const shiftEndsAt = openShift.get('shiftEndsAt')
  const shiftStartsAtMoment = timeService.timeMoment(shiftStartsAt)

  const shiftDayStart = shiftStartsAtMoment.clone().startOf('day')
  const startTimeMinutes = timeService.getMinutesFromStartOfDay(shiftStartsAt)
  const shiftDate = shiftStartsAtMoment.format('MMM DD, YYYY')
  const endTimeMinutes = timeService.getMinutesFromStartOfDay(shiftEndsAt)

  const [serviceTypes, setServiceTypes] = useState<WeScanServiceType[]>([])
  const [procedures, setProcedures] = useState<IWeScanProcedure[]>([])
  const [skillLevels, setSkillLevels] = useState<IWeScanLevel[]>([])
  const [availabilities, setAvailabilities] = useState<IWeScanRemoteStaffAvailability[] | null>([])
  const [minStartTime, setMinStartTime] = useState<string>()
  const [isPastDate, setIsPastDate] = useState(false)
  const [forceAllow, setForceAllow] = useState(false)

  const loggedInUser = appState?.getIn(['authentication', 'profile'])
  const firstName = loggedInUser?.get('firstName')
  const lastName = loggedInUser?.get('lastName')
  const email = loggedInUser?.get('email')
  const phone = loggedInUser?.get('phoneNumber')

  const lessThan30Days = timeService.getDaysDifference(shiftStartsAt) < 30

  const handleYesButtonClick = async () => {
    setForceAllow(true)
  }

  const onSubmit = async (_e: Event, formData: ICreateRemoteWeScanStaffFormData) => {
    const shiftDayId = openShift.get('shiftDayId')

    await controller
      .createRemoteStaffRequest(shiftDayId, formData, availabilities!)
      .then(() => {
        onCreated()
      })
      .finally(() => {
        onClose()
      })
  }

  const fetchProceduresAndSkills = (serviceType: string) => {
    setProcedures([])
    setSkillLevels([])
    setAvailabilities(null)

    if (!serviceType) {
      return
    }

    controller
      ?.getProceduresAndSkillsForServiceType(equipmentId, serviceType)
      .then((proceduresAndSkills: IWeScanRemoteEquipmentProcedureData) => {
        setProcedures(proceduresAndSkills.procedures)
        setSkillLevels(proceduresAndSkills.levels)
      })
  }

  const fetchAvailabilities = (formData: ICreateRemoteWeScanStaffFormData) => {
    controller
      .getRemoteStaffAvailabilities(equipmentId, timeService, shiftStartsAt, formData)
      .then((availabilitiesData: IWeScanRemoteStaffAvailability[]) => {
        setAvailabilities(availabilitiesData)
      })
  }

  useEffect(() => {
    controller?.getRemoteServicesTypesForEquipment(equipmentId).then((equipmentServiceTypes) => {
      setServiceTypes(equipmentServiceTypes)
    })

    // return () => {}
  }, [controller, equipmentId])

  remoteStaffRequestFormInputDefinition.properties.equipmentName.value = equipmentName
  remoteStaffRequestFormInputDefinition.properties.date.value = shiftDate
  remoteStaffRequestFormInputDefinition.properties.startTime.value = startTimeMinutes || 0
  remoteStaffRequestFormInputDefinition.properties.endTime.value = endTimeMinutes || 0
  remoteStaffRequestFormInputDefinition.properties.requestor.value = { firstName, lastName, email, phone }

  remoteStaffRequestFormInputDefinition.properties.serviceType.enum = serviceTypes
  remoteStaffRequestFormInputDefinition.properties.procedures.enum = procedures
  remoteStaffRequestFormInputDefinition.properties.skillLevel.enum = skillLevels
  remoteStaffRequestFormInputDefinition.properties.availabilities.enum = availabilities

  remoteStaffRequestFormInputDefinition.properties.startTime.validateField = (value: number) =>
    validateStartTime(controller, shiftStartsAt, value)

  if (isPastDate) {
    remoteStaffRequestFormInputDefinition.properties.availabilities.message = `Minimum allowed start time to check for availabilities is ${minStartTime}`
  }

  const prevStateOfStartTime = useRef<number | null>()
  const prevStateOfEndTime = useRef<number | null>()
  const prevStateOfServiceType = useRef<WeScanServiceType | null>()
  const prevStateOfProcedures = useRef<string | null>()

  const onFormStateChanged = (newFormState: ICreateRemoteWeScanStaffFormData) => {
    const newStartTimeValue = newFormState?.startTime || 0
    const newEndTimeValue = newFormState?.endTime || 0
    const newServiceTypeValue = newFormState?.serviceType

    const hasStartTimeChanged = newStartTimeValue !== prevStateOfStartTime.current

    let nearestCurrentDateTime = timeService.trueNow().set({ second: 0, millisecond: 0 })
    const currentDayStart = nearestCurrentDateTime.clone().startOf('day')
    const minutesFromStartOfDay = nearestCurrentDateTime.diff(currentDayStart, 'minutes')
    const minutesRemainder = minutesFromStartOfDay % SLOT_DURATION_IN_MINUTES

    if (minutesRemainder !== 0) {
      nearestCurrentDateTime = nearestCurrentDateTime.subtract(minutesRemainder, 'minutes')
    }

    const minimumAllowedStartsAt = nearestCurrentDateTime.add(SLOT_DURATION_IN_MINUTES, 'minutes')
    const dateMoment = timeService.timeMoment(shiftDayStart).startOf('day')
    const startTimeMoment = dateMoment.clone().add(newStartTimeValue || 0, 'minutes')

    const isPast = startTimeMoment.isBefore(minimumAllowedStartsAt)
    setIsPastDate(isPast)
    setMinStartTime(minimumAllowedStartsAt.format('MMM DD, hh:mm A'))

    if (hasStartTimeChanged) {
      prevStateOfStartTime.current = newStartTimeValue
    }

    const hasEndTimeChanged = newEndTimeValue !== prevStateOfEndTime.current

    if (hasEndTimeChanged) {
      prevStateOfEndTime.current = newEndTimeValue
    }

    const hasServiceTypeChanged = newServiceTypeValue !== prevStateOfServiceType.current

    if (hasServiceTypeChanged) {
      fetchProceduresAndSkills(newServiceTypeValue)
      prevStateOfServiceType.current = newServiceTypeValue
      newFormState.procedures = ''
      newFormState.skillLevel = void 0
      newFormState.availabilities = []
    }

    const newProceduresValue = newFormState?.procedures
    const hasProcedureChanged = newProceduresValue !== prevStateOfProcedures.current
    const shouldReloadAvailabilities =
      !isPast && (hasStartTimeChanged || hasEndTimeChanged || hasServiceTypeChanged || hasProcedureChanged)

    if (hasProcedureChanged) {
      prevStateOfProcedures.current = newProceduresValue
    }

    if (isPast || !newServiceTypeValue || !newProceduresValue) {
      newFormState.availabilities = []
      setAvailabilities(null)
    }

    if (shouldReloadAvailabilities && newServiceTypeValue && newProceduresValue) {
      newFormState.availabilities = []
      fetchAvailabilities(newFormState)
    }
  }

  if (!forceAllow && lessThan30Days) {
    return (
      <WarningModal
        title={t('equipments.create_remote_wescan_request.header')}
        message={t('equipments.create_remote_wescan_request.before_30_days_message')}
        onProceed={handleYesButtonClick}
        onAbort={onClose}
      />
    )
  }

  return (
    <Modal
      visible={true}
      title={t('equipments.create_remote_wescan_request.create_button_text')}
      dismissOnOutsideClick={false}
      onClose={onClose}
      showClose={true}
    >
      <div>
        <section className="modal-form">
          <Form
            entityType="RemoteWeScanStaff"
            isPaginated={true}
            onSubmit={onSubmit}
            onCancel={onClose}
            inputDefinition={remoteStaffRequestFormInputDefinition}
            appState={appState}
            onFormChange={onFormStateChanged}
            listOfValues={procedures}
            timeService={timeService}
          />
        </section>
      </div>
    </Modal>
  )
}

export default withAppContext(CreateRemoteWeScanStaffRequest)
