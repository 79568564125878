import { t } from 'i18n'
import { useState } from 'react'
import classNames from 'classnames'
import RemoteWeScanRequestAppointmentRow from './RemoteWeScanRequestAppointmentRow'
import RemoteWeScanStaffRequestController from '../RemoteWeScanStaffRequestController'

import { TimeService } from 'services'
import { IRemoteStaffRequest } from 'stores/remoteWorkflowStore/models/remoteStaffRequest'

interface IViewRemoteWeScanStaffRowProps {
  remoteStaffRequest: IRemoteStaffRequest
  timeService: TimeService
  controller: RemoteWeScanStaffRequestController
  handleCancelRequestButtonClick: (_remoteStaffRequest: IRemoteStaffRequest) => void
  handleViewRequestDetailsButtonClick: (_remoteStaffRequest: IRemoteStaffRequest) => void
}

export default function RemoteWeScanStaffRequestRow(props: IViewRemoteWeScanStaffRowProps) {
  const [showAppointmentSlotRows, setShowAppointmentSlotRows] = useState(false)
  const { remoteStaffRequest, controller } = props

  const weScanRequestDetails = remoteStaffRequest.requestDetails.wescan!
  const requestedBy = `${weScanRequestDetails.requestor.firstName} ${weScanRequestDetails.requestor.lastName}`
  const state = remoteStaffRequest.state
  const canRequestBeCancelled = controller.canRemoteWeScanRequestBeCancelled(remoteStaffRequest)
  const isPastCurrentTime = controller.isRequestPastCurrentTime(remoteStaffRequest)
  const appointmentSlotText = controller.getAppointmentSlotText(weScanRequestDetails.appointments)

  const toggleShowAppointmentSlotRows = () => {
    setShowAppointmentSlotRows(!showAppointmentSlotRows)
  }

  const handleCancelRequestButtonClick = (e: MouseEvent) => {
    e.stopPropagation()
    props.handleCancelRequestButtonClick(remoteStaffRequest)
  }

  const handleViewRequestDetailsButtonClick = (e: MouseEvent) => {
    e.stopPropagation()
    props.handleViewRequestDetailsButtonClick(remoteStaffRequest)
  }

  return (
    <>
      <div className="row bg-white" onClick={toggleShowAppointmentSlotRows}>
        <div>{weScanRequestDetails.serviceType}</div>
        <div>{weScanRequestDetails.procedures?.join(', ')}</div>
        <div title={appointmentSlotText}>{appointmentSlotText}</div>
        <div>{state}</div>
        <div>{requestedBy}</div>
        <div>
          <div className="actions">
            <div>
              <sh-icon
                class="regent-gray"
                icon="show"
                title="View Details"
                onClick={handleViewRequestDetailsButtonClick}
              ></sh-icon>
            </div>
            {canRequestBeCancelled && (
              <>
                <div id="cancelIconContainer">
                  <sh-icon
                    class="regent-gray"
                    icon="cancel"
                    disabled={isPastCurrentTime ? true : undefined}
                    onClick={handleCancelRequestButtonClick}
                  ></sh-icon>
                </div>
                <sh-tooltip
                  label={
                    isPastCurrentTime
                      ? t('equipments.request_cannot_be_cancelled_message')
                      : t('equipments.cancel_wescan_request_button_text')
                  }
                  target="cancelIconContainer"
                  placement="top"
                  variation="short"
                ></sh-tooltip>
              </>
            )}
          </div>
        </div>
        <div>
          <div>
            <button>
              <i
                className={classNames({
                  'regent-gray': true,
                  'icon-up big': showAppointmentSlotRows,
                  'icon-right': !showAppointmentSlotRows
                })}
              />
            </button>
          </div>
        </div>
      </div>
      {showAppointmentSlotRows &&
        weScanRequestDetails.appointments.map((appointment, index) => (
          <RemoteWeScanRequestAppointmentRow
            key={appointment.appointmentId}
            appointment={appointment}
            controller={controller}
            remoteStaffRequest={remoteStaffRequest}
            handleCancelRequestButtonClick={props.handleCancelRequestButtonClick}
            index={index}
          />
        ))}
    </>
  )
}
