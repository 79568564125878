// @ts-ignore
import Form from '@humanics/he-react-common/lib/admin/components/Form'

import { t } from 'i18n'
import { cloneDeep } from 'lodash'
import { Modal } from 'Common/components'
import { withAppContext } from 'AppContext'
import { inputDefinition } from '../viewRemoteStaffRequestFormDefinition'

import { TimeService } from 'services'
import RemoteWeScanStaffRequestController from '../RemoteWeScanStaffRequestController'
import { IRemoteStaffRequest } from 'stores/remoteWorkflowStore/models/remoteStaffRequest'

interface IWeScanRequestDetailsProps {
  appState: any
  openShift: any
  timeService: TimeService
  remoteStaffRequest: IRemoteStaffRequest
  controller: RemoteWeScanStaffRequestController
  onClose: () => void
}

function WeScanRequestDetails(props: IWeScanRequestDetailsProps) {
  const { appState, timeService, remoteStaffRequest, onClose, openShift, controller } = props

  const equipmentName = openShift.get('shiftName')
  const formDefinition = cloneDeep(inputDefinition)
  const requestDetails = remoteStaffRequest.requestDetails.wescan!

  controller.setViewDetailsFormDefinitionValues(formDefinition, requestDetails, equipmentName)

  return (
    <Modal
      visible={true}
      title={t('equipments.view_remote_wescan_request.header')}
      dismissOnOutsideClick={false}
      onClose={onClose}
      showClose={true}
    >
      <div>
        <section className="modal-form">
          <Form
            canSubmit={false}
            cancelLabel={t('close')}
            isEdit={true}
            isReadOnly={true}
            entityType="RemoteWeScanRequestDetails"
            isPaginated={true}
            onCancel={onClose}
            inputDefinition={formDefinition}
            appState={appState}
            timeService={timeService}
          />
        </section>
      </div>
    </Modal>
  )
}

export default withAppContext(WeScanRequestDetails)
