import { List, Map } from 'immutable'
import { isFunction, memorizeObject } from 'utils'
import Notes from './Notes'
import fluxStore, { getState } from '@humanics/he-react-common/lib/stores/fluxStore'
import PreloadFlatShifts from './PreloadFlatShifts'
import LoadFlatShifts from './LoadFlatShifts'
import LoadFlatCalendar from './LoadFlatCalendar'
import StaffEvents from './StaffEvents'
import StaffUnavailabilityReminders from './StaffUnavailabilityReminders'
import CalendarDay from './CalendarDay'
import OtherStaff from './OtherStaff'
import OtherUnitsStaff from './OtherUnitsStaff'
import dataBuilders from './dataBuilders'

export default function CalendarStore() {
  const defaultState = Map({
    roles: List(),
    dateRange: Map(),
    staffHoursMap: Map(),
    notes: Map(),
    cache: Map(),
    kpi: List()
  })

  const { updateCalendar, updateCalendarCache } = fluxStore({
    updateCalendar: _updateCalendar,
    updateCalendarCache: _updateCalendarCache
  })
  const stateActions = fluxStore({ updateCalendarCell, updateCalendarCells })

  return {
    initialize,
    getPath,
    updateCalendar,
    defaultState,
    ...stateActions,
    ...Notes.actions,
    ...OtherStaff.actions,
    ...CalendarDay.actions,
    ...StaffEvents.actions,
    ...LoadFlatShifts.actions,
    ...OtherUnitsStaff.actions,
    ...LoadFlatCalendar.actions,
    ...PreloadFlatShifts.actions,
    ...StaffUnavailabilityReminders.actions
  }

  function initialize(state, context) {
    const { gqlClient: gqlClientOriginal, ...rest } = context

    const gqlClient = memorizeObject(gqlClientOriginal, _getCachedValue)

    const helperContext = {
      gqlClient,
      ...rest,
      ...dataBuilders,
      updateCalendar,
      getCalendar,
      getPath,
      updateCalendarCache
    }

    Notes.initialize(helperContext)
    OtherStaff.initialize(helperContext)
    CalendarDay.initialize(helperContext)
    StaffEvents.initialize(helperContext)
    LoadFlatShifts.initialize(helperContext)
    OtherUnitsStaff.initialize(helperContext)
    LoadFlatCalendar.initialize(helperContext)
    PreloadFlatShifts.initialize(helperContext)
    StaffUnavailabilityReminders.initialize(helperContext)

    return state.set('autoScheduleCalendar', defaultState)
  }

  function updateCalendarCells(state, cellsPaths, data) {
    return _updateCalendar(state, (autoScheduleCalendar) => {
      cellsPaths.forEach((pathParams) => {
        const cellPath = getPath(pathParams)
        autoScheduleCalendar = autoScheduleCalendar.updateIn(cellPath, (prevCell) => prevCell.merge(data))
      })

      return autoScheduleCalendar
    })
  }

  function updateCalendarCell(state, pathParams, cell) {
    const cellPath = getPath(pathParams, true)
    return state.updateIn(cellPath, (prevCell) => prevCell.merge(cell))
  }

  function _updateCalendar(state, value, rewrite = true) {
    if (isFunction(value)) {
      return state.update('autoScheduleCalendar', value)
    }

    return state.update('autoScheduleCalendar', (autoScheduleCalendar) => {
      if (rewrite) {
        return autoScheduleCalendar.merge(value)
      }
      return autoScheduleCalendar.mergeDeep(value)
    })
  }

  function _customStringCompare(a, b) {
    const lowerCaseA = a.toLowerCase()
    const lowerCaseB = b.toLowerCase()

    if (lowerCaseA < lowerCaseB) {
      return -1
    } else if (lowerCaseA > lowerCaseB) {
      return 1
    } else {
      return 0
    }
  }

  function _getCachedValue(operation, args = []) {
    const isMutation = operation === 'mutate'
    if (isMutation) {
      return
    }

    let queryName
    let parameters = args
    const isQuery = operation === 'query'

    if (isQuery) {
      const [, operationParameters, options = {}] = args
      ;({ queryName } = options)
      if (!queryName) {
        return
      }

      parameters = operationParameters
    }

    const parametersHash = parameters && JSON.stringify(parameters, Object.keys(parameters).sort(_customStringCompare))

    const path = ['autoScheduleCalendar', 'cache', operation]

    if (queryName) {
      path.push(queryName)
    }

    if (parametersHash) {
      path.push(parametersHash)
    }

    const value = getState().getIn(path)

    if (value) {
      const pathToRemove = path.slice(1)
      updateCalendar((autoScheduleCalendar) => autoScheduleCalendar.removeIn(pathToRemove))
    }

    return value
  }

  function _updateCalendarCache(state, operation, parameters, promise, options = {}) {
    const isMutation = operation === 'mutate'
    if (isMutation) {
      return state
    }

    const path = ['autoScheduleCalendar', 'cache', operation]

    const isQuery = operation === 'query'
    const queryName = isQuery && options.queryName

    if (queryName) {
      path.push(queryName)
    }
    const parametersHash = parameters && JSON.stringify(parameters, Object.keys(parameters).sort(_customStringCompare))

    if (parametersHash) {
      path.push(parametersHash)
    }

    return state.setIn(path, promise)
  }

  function getCalendar() {
    return getState().get('autoScheduleCalendar')
  }

  function getPath(params, includeCalendar = false) {
    const keys = Object.keys(params)
    keys.forEach((key) => {
      if (typeof params[key] === 'undefined') {
        throw new Error(`${key} is undefined`)
      }
    })

    const { roleIndex, shiftIndex, staffIndex, cellIndex, shiftDayIndex } = params
    const rolePath = roleIndex >= 0 ? ['roles', roleIndex] : []
    const shiftPath = shiftIndex >= 0 ? ['shifts', shiftIndex] : []
    const shiftDayPath = shiftDayIndex >= 0 ? ['shiftDays', shiftDayIndex] : []

    const shift = getCalendar()?.getIn([...rolePath, ...shiftPath])
    const rowsKey = shift?.get('viewPreference') === 'shift' || shift?.get('isOnCall') ? 'shiftViewRows' : 'staff'

    const staffPath = staffIndex >= 0 ? [rowsKey, staffIndex] : []
    const cellPath = cellIndex >= 0 ? ['cells', cellIndex] : []
    const relativePath = [...rolePath, ...shiftPath, ...shiftDayPath, ...staffPath, ...cellPath]

    return includeCalendar ? ['autoScheduleCalendar', ...relativePath] : relativePath
  }
}
