import { Component } from 'react'
import OpenShiftStatus from './OpenShiftStatus'
import OpenShiftActivity from './OpenShiftActivity'
import { OpenShiftEntity } from 'entityWrappers'
import { navigate } from 'Navigation'
import classNames from 'classnames'

export default class OpenShift extends Component {
  render() {
    const { openShift, compensateCol, unitRoles, weekDay, day, month } = this.props
    const openShiftEntity = new OpenShiftEntity(openShift)
    const {
      createdAtDateTime,
      time,
      staffMismatch,
      isPosted,
      pendingToConfirmUserIds,
      isFilled,
      minimumCoverageRequired,
      maximumCoverageRequired
    } = openShiftEntity

    const openShiftStatusProps = {
      isPosted,
      pendingToConfirmUserIds,
      isFilled,
      openShift,
      staffMismatch
    }
    const roleId = openShift.get('unitRoleId')
    const role = unitRoles.find((item) => item.get('id') === roleId)
    const roleName = role.get('name')
    const shiftName = openShift.get('shiftName')
    const isImportant = openShift.get('isImportant')

    const openShiftActivityProps = { openShift }

    return (
      <>
        {!compensateCol && (
          <div className="semibold hx-cards-date bg-porcelain">
            <span className="icon icon-calendar" />
            <span className="upper black">
              &nbsp;&nbsp;{month} {day}, {weekDay}
            </span>
          </div>
        )}
        <div
          className={classNames('hx-open-shift-item-week bg-white', {
            'bg-important': !!isImportant
          })}
          onClick={this.navigateToOpenShift(openShiftEntity)}
        >
          <div className="trout semibold col-3">{shiftName}</div>
          <div className="col-2">{time}</div>
          <div className="col-3">{roleName}</div>
          <div className="text-center semibold col-1">{Math.max(minimumCoverageRequired, 0)}</div>
          <div className="text-center semibold col-1">{Math.max(maximumCoverageRequired, 0)}</div>
          <OpenShiftStatus {...openShiftStatusProps} />
          <OpenShiftActivity {...openShiftActivityProps} />
          <div className="semibold col-1">{createdAtDateTime}</div>
          <div className="important-icon">
            {!!isImportant && <div className="icon icon-Exclamation-Point red-orange border-redorange" />}
          </div>
        </div>
      </>
    )
  }

  navigateToOpenShift = (openShiftEntity) => () => {
    const { isPosted, id, shiftDayId } = openShiftEntity

    if (isPosted) {
      navigate.from.OpenShifts.to.ShiftOpportunity({ id })
    } else {
      navigate.from.OpenShifts.to.OpenShift({ shiftDayId })
    }
  }
}
