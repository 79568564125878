import { pick } from 'lodash'
import stores from 'stores'
const { calendarStore, autoScheduleCalendarStore } = stores

const getCellContextMenuProps = (contextProps) => {
  const { calendarType } = contextProps
  const store = getAppropriateStore(calendarType)
  const cellContextMenuProps = pick(contextProps, [
    'calendar',
    'popupProps',
    'scrollTop',
    'activeDateRange',
    'unit',
    'unitManager',
    'selection',
    'timeService',
    'hideContextMenu',
    'showCellDetailsPopup',
    'addTimeOffs',
    'changeTimeOffs',
    'changeStaffEventForSelection',
    'createStaffEventsForSelection',
    'deleteSelectedStaffEvents',
    'isCellContextMenuVisible'
  ])
  cellContextMenuProps.calendarStore = store
  return cellContextMenuProps
}

const getCellDetailsProps = (contextProps) => {
  const { calendarType } = contextProps
  const store = getAppropriateStore(calendarType)
  const cellDetailsProps = pick(contextProps, [
    'units',
    'unitId',
    'onCancel',
    'facility',
    'selection',
    'calendar',
    'scrollTop',
    'popupProps',
    'timeService',
    'shiftsMap',
    'unitManager',
    'generalStore',
    'eventVariants',
    'isCellDetailsVisible',
    'addTimeOffs',
    'changeTimeOffs',
    'cellsPathsInProcessing',
    'updateEventForSelection',
    'changeStaffEventForSelection',
    'deleteSelectedStaffEvents',
    'createStaffEventsForSelection',
    'getStaffEventDetails',
    'getViewModel'
  ])
  cellDetailsProps.calendarStore = store
  return cellDetailsProps
}
const composeIndicatorsClasses = (indicators) => {
  return {
    '_highlight-annotation': indicators.get('noteOrExplanation'),
    '_highlight-lock': indicators.get('locks'),
    '_highlight-info': indicators.get('info'),
    '_highlight-available': indicators.get('available'),
    '_highlight-unavailable': indicators.get('unavailable')
  }
}

const componseModeClasses = (mode) => {
  return {
    _modeFull: mode === 'full',
    _modeWeek: mode === 'week',
    _modeDay: mode === 'day'
  }
}

const getAppropriateStore = (calendarType) => {
  if (calendarType === 'autoScheduleCalendar') {
    return autoScheduleCalendarStore
  }
  return calendarStore
}

export {
  getCellContextMenuProps,
  getCellDetailsProps,
  composeIndicatorsClasses,
  componseModeClasses,
  getAppropriateStore
}
