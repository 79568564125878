import { IViewRemoteWeScanStaffRequestInputFormDefinition } from 'stores/remoteWorkflowStore/models/wescan/viewRemoteWeScanStaffRequestInputFormDefinition'
import createRemoteStaffRequestFormDefinition from './createRemoteStaffRequestFormDefinition'

export const inputDefinition: IViewRemoteWeScanStaffRequestInputFormDefinition = {
  properties: {
    ...(createRemoteStaffRequestFormDefinition.properties as any),
    date: {
      ...createRemoteStaffRequestFormDefinition.properties.date,
      isVisible: () => false
    },
    startTime: {
      type: 'string',
      title: 'Start Time'
    },
    endTime: {
      type: 'string',
      title: 'End Time'
    },
    serviceType: {
      type: 'string',
      title: 'Service Type'
    },
    procedures: {
      type: 'string',
      title: 'Procedures'
    },
    skillLevel: {
      type: 'string',
      title: 'Skill Level'
    }
  }
}
