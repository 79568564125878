import { t } from 'i18n'
import WarningModal from './WarningModal'

import { TimeService } from 'services'
import RemoteWeScanStaffRequestController from '../RemoteWeScanStaffRequestController'
import { IRemoteStaffRequest, IWeScanAppointment } from 'stores/remoteWorkflowStore/models/remoteStaffRequest'

export interface ICancelRemoteWeScanStaffRequestProps {
  remoteStaffRequest: IRemoteStaffRequest
  timeService: TimeService
  controller: RemoteWeScanStaffRequestController
  appointment?: IWeScanAppointment
  onCancelSuccess?: () => void
  onCancelAbort?: () => void
}

export default function CancelRemoteWeScanStaffRequest(props: ICancelRemoteWeScanStaffRequestProps) {
  const { remoteStaffRequest, onCancelSuccess, onCancelAbort, timeService, controller } = props

  const firstWeScanAppointmentStartsAt = remoteStaffRequest.requestDetails.wescan!.appointments[0].startsAt
  const isAppointmentCancellationRequest = !!props.appointment

  const isPast = timeService.isPastCurrentTime(firstWeScanAppointmentStartsAt)

  const message = isAppointmentCancellationRequest
    ? controller.getAppointmentCancellationMessage(firstWeScanAppointmentStartsAt, props.appointment!)
    : controller.getRequestCancellationMessage(firstWeScanAppointmentStartsAt)

  const headerTitle = isAppointmentCancellationRequest
    ? t('equipments.cancel_wescan_appointment_header')
    : t('equipments.cancel_wescan_request_header')

  const handleYesButtonClick = async () => {
    let promiseToExecute: Promise<void>

    if (isAppointmentCancellationRequest) {
      const { appointmentId } = props.appointment!

      promiseToExecute = controller.cancelAppointment(remoteStaffRequest, appointmentId)
    } else {
      promiseToExecute = controller.cancelRemoteStaffRequest(remoteStaffRequest)
    }

    await promiseToExecute.finally(() => {
      onCancelSuccess?.()
    })
  }

  const handleGoBackButtonClick = () => {
    onCancelAbort?.()
  }

  return (
    <WarningModal
      title={headerTitle}
      message={message}
      hideYesButton={isPast}
      onProceed={handleYesButtonClick}
      onAbort={handleGoBackButtonClick}
    />
  )
}
