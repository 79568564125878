import { Component } from 'react'
import { ImageGraceful } from '../ImageGraceful'
import './Avatar.scss'

export default class Avatar extends Component {
  static defaultProps = {
    size: 32,
    renderVisibleOnly: false,
    visible: true,
    avatarClass: 'hx-staff-avatar rounded',
    initialsClass: 'hx-staff-initials rounded',
    extendedClass: ''
  }

  render() {
    const {
      profile,
      size,
      visible,
      renderVisibleOnly,
      initialsClass,
      avatarClass,
      statusIcon,
      extendedClass,
      preview,
      children: customAvatar
    } = this.props
    let avatarUrl = profile?.get('avatarUrl')

    if (renderVisibleOnly && !visible) {
      avatarUrl = undefined
    }

    return (
      <div className="hx-avatar-container">
        {customAvatar ? (
          <div className={`${initialsClass} ${extendedClass} image-${size}`}>{customAvatar}</div>
        ) : (
          <ImageGraceful
            className={`${avatarClass} ${extendedClass} image-${size}`}
            src={preview || avatarUrl}
            fallbackComponent={this.showInitials(profile, size, initialsClass, extendedClass)}
            alt=""
          />
        )}
        <div className="hx-avatar-status-icon">{statusIcon}</div>
      </div>
    )
  }

  showInitials = (profile, size, initialsClass, extendedClass) => {
    const firstName = profile?.get('firstName')
    const lastName = profile?.get('lastName')
    const initials = `${firstName?.charAt(0) || '_'}${lastName?.charAt(0) || '_'}`.toUpperCase()

    return (
      <div className={`${initialsClass} ${extendedClass} image-${size}`}>
        <sh-text size="header-2">{initials}</sh-text>
      </div>
    )
  }
}
