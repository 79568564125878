import { PureComponent } from 'react'
import { PopUp } from 'Manager/components'
import Tabs from './Tabs'
import ShiftForm from './ShiftForm'
import TimeOffForm from './TimeOffForm'
import { pick } from 'lodash'
import { Staff } from 'entityWrappers'
import CellDetailsController from './CellDetailsController'
import './CellDetails.scss'
import { hasPartialTimeOff, hasTimeOff, hasShiftEvent, hasFullDayOff } from './cellDetailsUtils'
import { remoteServiceUserIds } from 'utils/remoteServiceUserIds'

export default class CellDetails extends PureComponent {
  constructor(props) {
    super(props)

    this.controller = new CellDetailsController(this)
    this.state = { ...this.controller.defaultState }
  }

  componentDidUpdate(prevProps) {
    const { isCellDetailsVisible, facility } = this.props

    const isShiftsMapLoaded = facility.get('isShiftsMapLoaded')
    const isManagersMapLoaded = facility.get('isManagersMapLoaded')
    const isFacilityDataLoaded = isShiftsMapLoaded && isManagersMapLoaded

    if (!isFacilityDataLoaded && isCellDetailsVisible) {
      this.controller.loadFacilityData()
    }

    const { defaultTabId } = CellDetailsController
    const { isCellDetailsVisible: prevIsCellDetailsVisible } = prevProps

    const isDefaultTabActive = this.state.activeTabId === defaultTabId
    const isCellDetailsVisibiityChanged = isCellDetailsVisible !== prevIsCellDetailsVisible
    const shouldShowDefaultTab = isCellDetailsVisibiityChanged && !isDefaultTabActive

    if (shouldShowDefaultTab) {
      this.showTab(defaultTabId)
    }
  }

  render() {
    const { isCellDetailsVisible, selection, unitId, timeService } = this.props
    if (!isCellDetailsVisible) {
      return null
    }

    const selectedCellsCount = selection.get('cells').size
    if (selectedCellsCount === 0) {
      return null
    }

    const { popupProps, scrollTop, facility } = this.props
    const { activeTabId } = this.state

    const isShiftsMapLoaded = facility.get('isShiftsMapLoaded')
    const isManagersMapLoaded = facility.get('isManagersMapLoaded')
    const isFacilityDataLoaded = isShiftsMapLoaded && isManagersMapLoaded

    const style = { width: 380 }
    const { cells } = this.controller.selectedObjects
    const cell = cells.last()

    const isRemoteStaff = remoteServiceUserIds.includes(cell.get('staff'))
    if (isRemoteStaff) {
      return null
    }

    const isDummyCell = cell.get('isDummy')

    const { staff, days } = this.controller.selectedObjects
    const user = new Staff(staff)
    const isHomeUnitForAllDays = days.every((day) => {
      const dateTime = day.get('dateTime')
      const homeUnitId = user.getHomeUnitIdForDate(dateTime, timeService)
      return homeUnitId === unitId
    })

    const isSecondaryStaff = staff.get('shiftId') === 'secondary-staff-shift'
    const canDisplayTimeOff = isHomeUnitForAllDays && !isSecondaryStaff
    const tabTitlesMap = this._getTabTitlesMap()

    const tabProps = {
      tabs: tabTitlesMap,
      activeTabId,
      onClick: this.showTab
    }

    const isProcessing = isDummyCell || !isFacilityDataLoaded
    const isFormVisible = !isProcessing

    return (
      <PopUp {...popupProps} scrollTop={scrollTop} className="hx-cell-details">
        <div style={style}>
          {!cell.get('isAvatarCell') && <Tabs {...tabProps} />}

          {isProcessing && this.renderSpinner()}
          {isFormVisible && this.renderForms(tabTitlesMap, canDisplayTimeOff)}
        </div>
      </PopUp>
    )
  }

  _getTabTitlesMap() {
    const selectedCellsCount = this.props.selection.get('cells').size
    const pluralSuffix = selectedCellsCount > 1 ? 's' : ''

    const addShiftTitle = 'Add Shift' + pluralSuffix
    const addTimeOffTitle = 'Add Time Off'
    const updateShiftTitle = 'Update Shift' + pluralSuffix
    const updateTimeOffTitle = 'Update Time Off'

    const cell = this.controller.selectedObjects.cells.last()
    if (hasFullDayOff(cell)) {
      return { timeOffForm: updateTimeOffTitle }
    } else if (hasShiftEvent(cell) && hasTimeOff(cell)) {
      return { shiftForm: updateShiftTitle, timeOffForm: updateTimeOffTitle }
    } else if (hasShiftEvent(cell) && !hasTimeOff(cell)) {
      return { shiftForm: updateShiftTitle, timeOffForm: addTimeOffTitle }
    } else if (!hasShiftEvent(cell) && hasTimeOff(cell)) {
      return { shiftForm: addShiftTitle, timeOffForm: updateTimeOffTitle }
    } else if (!hasShiftEvent(cell) && !hasTimeOff(cell)) {
      return { shiftForm: addShiftTitle, timeOffForm: addTimeOffTitle }
    }
    return {}
  }

  renderSpinner() {
    return (
      <section className="p15">
        <div className="loader">
          <i className="loading spindle" />
        </div>
      </section>
    )
  }

  renderForms(tabTitlesMap, canDisplayTimeOff) {
    const { eventVariants, getStaffEventDetails } = this.props
    const { activeTabId } = this.state
    const { selectedCells, cells, staff, shift, shiftDays, days } = this.controller.selectedObjects
    const { cellSubIndex } = selectedCells.last()
    const cell = cells.last()
    const day = days.first()
    const shiftDay = shiftDays.last()

    const isAvailableForScheduling = !hasTimeOff(cell) && !hasShiftEvent(cell)

    const isShiftFormShown = hasShiftEvent(cell) || activeTabId === 'shiftForm'
    const isTimeOffFormShown = canDisplayTimeOff && (hasTimeOff(cell) || activeTabId === 'timeOffForm')

    const isProcessing = this.controller.isSelectedCellsProcessing()

    const timeOffEventVaraints = eventVariants.filter((variant) => variant.get('type') === 'timeOff')

    const shiftId = shift.get('id')
    const shiftFormProps = pick(
      {
        ...this.props,
        shiftId,
        shiftDay,
        cell,
        day,
        days,
        staff,
        isProcessing,
        isShiftFormShown,
        cellSubIndex,
        getStaffEventDetails
      },
      [
        'day',
        'days',
        'cell',
        'staff',
        'units',
        'unitId',
        'shiftId',
        'shiftDay',
        'onCancel',
        'shiftsMap',
        'timeService',
        'unitManager',
        'isProcessing',
        'eventVariants',
        'calendarStore',
        'isShiftFormShown',
        'updateEventForSelection',
        'changeStaffEventForSelection',
        'deleteSelectedStaffEvents',
        'createStaffEventsForSelection',
        'cellSubIndex',
        'getStaffEventDetails',
        'getViewModel'
      ]
    )

    const timeOffFormProps = pick(
      {
        ...this.props,
        cell,
        day,
        isProcessing,
        isTimeOffFormShown,
        timeOffEventVaraints,
        actionTitle: tabTitlesMap.timeOffForm
      },
      [
        'day',
        'cell',
        'onCancel',
        'shiftsMap',
        'addTimeOffs',
        'isProcessing',
        'timeService',
        'calendarStore',
        'changeTimeOffs',
        // 'updateTimeOffNotes',
        'isTimeOffFormShown',
        'timeOffEventVaraints',
        'deleteSelectedStaffEvents',
        'actionTitle'
      ]
    )

    const timeOffPropsForShiftEvent = {
      ...timeOffFormProps,
      isTimeOffFormShown: activeTabId === 'timeOffForm'
    }
    const shiftFormPropsForShiftEvent = {
      ...shiftFormProps,
      isShiftFormShown: activeTabId === 'shiftForm'
    }
    return (
      <>
        {isAvailableForScheduling && (
          <>
            <ShiftForm {...shiftFormProps} />
            <TimeOffForm {...timeOffFormProps} actionTitle="Create Time Off" />
          </>
        )}

        {(hasShiftEvent(cell) || hasPartialTimeOff(cell)) && (
          <>
            <ShiftForm {...shiftFormPropsForShiftEvent} />
            <TimeOffForm {...timeOffPropsForShiftEvent} />
          </>
        )}

        {hasFullDayOff(cell) && <TimeOffForm {...timeOffFormProps} />}
      </>
    )
  }

  showTab = (activeTabId) => this.setState({ activeTabId })
}
