import { SpillProofDiv } from 'Common/components'
import './Shift.scss'
import getEndTimeForShift from 'utils/getEndTimeForShift'

const dayOfWeekKeys = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat']

function generateTooltipText(details) {
  return dayOfWeekKeys
    .map((day) => {
      if (details[day]) {
        return `${day.charAt(0).toUpperCase() + day.slice(1)}: ${details[day].startTime} - ${details[day].endTime}`
      }
      return null
    })
    .filter((detail) => !!detail)
    .join('\n')
}

function getOperationalDetails(shift) {
  const operationalDetails = shift.get('operationalDetails')
  if (!operationalDetails) return {}
  const defaultStartTime = shift.get('startTime')
  const defaultEndTime = getEndTimeForShift(shift.toJS())
  const timeMap = {}
  for (const day of dayOfWeekKeys) {
    const operationalDetail = operationalDetails.get(day)?.get('operationalDetail')
    if (
      !!operationalDetail &&
      (operationalDetail.get('startTime') !== defaultStartTime || operationalDetail.get('endTime') !== defaultEndTime)
    ) {
      timeMap[day] = { startTime: operationalDetail.get('startTime'), endTime: operationalDetail.get('endTime') }
    }
  }

  return timeMap
}

function VShiftTitle({ shift, shiftRange, calendarType }) {
  const shiftDisplayAbbreviation = shift.get('displayAbbreviation')
  const shiftDisplayColor = shift.get('displayColor')
  const isLegendRequired = shiftDisplayAbbreviation
  const additionalCSS = calendarType === 'autoScheduleCalendar' ? { width: '65%', textWrap: 'nowrap' } : {}
  const operationalDetails = getOperationalDetails(shift)
  const hasOperationalDetails = Object.keys(operationalDetails).length > 0
  const tooltipText = hasOperationalDetails && generateTooltipText(operationalDetails)
  const shiftRangeKey = 'shift_range_' + shift.get('id')
  return (
    <aside className="col-2 row align-middle flex-nowrap">
      <span className="upper smaller bold river-bad col-5 pr10">
        <section>
          <SpillProofDiv id={shift.get('id')} tooltipPosition="right" hasCustomTooltip={true}>
            {shift.get('name')}
          </SpillProofDiv>
        </section>
      </span>
      <span className="smaller bold river-bad text-right col-5" style={{ ...additionalCSS }}>
        <span id={shiftRangeKey}>{shiftRange}</span>
        {hasOperationalDetails && (
          <sh-tooltip class="shui-tooltip-custom" target={shiftRangeKey} label={tooltipText} placement="right" />
        )}
      </span>
      <span className="upper smaller bold river-bad col-2 align-right row align-middle">
        {isLegendRequired && (
          <span
            className="row align-middle align-center legend"
            style={{ color: shiftDisplayColor, background: !shiftDisplayAbbreviation && shiftDisplayColor }}
          >
            {shiftDisplayAbbreviation}
          </span>
        )}
      </span>
    </aside>
  )
}
export default VShiftTitle
