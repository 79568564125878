import RemoteWeScanStaffRequestRow from './RemoteWeScanStaffRequestRow'

import { TimeService } from 'services'
import { IRemoteStaffRequest } from 'stores/remoteWorkflowStore/models/remoteStaffRequest'
import RemoteWeScanStaffRequestController from '../RemoteWeScanStaffRequestController'

interface IViewRemoteWeScanStaffListProps {
  remoteStaffRequests: IRemoteStaffRequest[]
  timeService: TimeService
  controller: RemoteWeScanStaffRequestController
  handleCancelRequestButtonClick: (_remoteStaffRequest: IRemoteStaffRequest) => void
  handleViewRequestDetailsButtonClick: (_remoteStaffRequest: IRemoteStaffRequest) => void
}

export default function RemoteWeScanStaffRequestsList(props: IViewRemoteWeScanStaffListProps) {
  const { remoteStaffRequests } = props

  return (
    <>
      {remoteStaffRequests.map((remoteStaffRequest: IRemoteStaffRequest) => (
        <RemoteWeScanStaffRequestRow
          key={remoteStaffRequest.id}
          remoteStaffRequest={remoteStaffRequest}
          timeService={props.timeService}
          controller={props.controller}
          handleCancelRequestButtonClick={props.handleCancelRequestButtonClick}
          handleViewRequestDetailsButtonClick={props.handleViewRequestDetailsButtonClick}
        />
      ))}
    </>
  )
}
