export function getDefaultDetailsLengthForShift(defaultDetails) {
  const { startTime, endTime } = defaultDetails
  const [startTimeHours, startTimeMinutes] = startTime.split(':').map(Number)
  const [endTimeHours, endTimeMinutes] = endTime.split(':').map(Number)

  const startTimeLength = startTimeHours * 60 + startTimeMinutes
  const endTimeLength = endTimeHours * 60 + endTimeMinutes

  return Math.abs(endTimeLength - startTimeLength)
}
