export default class OpenShiftActionService {
  constructor(openShiftEntity, openShiftStore, facilityUsersMap, unit) {
    this.openShiftEntity = openShiftEntity
    this.openShiftStore = openShiftStore
    this.facilityUsersMap = facilityUsersMap
    this.unit = unit
  }

  buildShiftOpportunityParameters(openShiftEntity) {
    let {
      note,
      isBonusPay,
      eligibleUserIds,
      acceptanceCriteria,
      isAlertEligibleStaff,
      isCrisisPay: crisisPay
    } = openShiftEntity

    return {
      note,
      crisisPay,
      isBonusPay,
      eligibleUserIds,
      acceptanceCriteria,
      isAlertEligibleStaff
    }
  }

  addStaffToShift = async (openShiftStaff) => {
    const { facilityUsersMap } = this
    const { id, unitId, shiftId, isPosted, startsAt, duration } = this.openShiftEntity
    const { userId, isEligible, isAccepted } = openShiftStaff

    if (isPosted && isEligible && isAccepted) {
      return this.openShiftStore.approveStaffResponse(id, userId, facilityUsersMap).catch(() => this.reloadOpenShift())
    } else {
      return this.openShiftStore
        .createStaffEvent({
          userId,
          unitId,
          shiftId,
          startsAt,
          duration
        })
        .catch(() => {
          // mute exception
        })
        .finally(() => this.reloadOpenShift())
    }
  }

  reloadOpenShift() {
    const { facilityUsersMap, unit } = this
    const shifts = unit.get('shifts')
    const shiftsById = shifts.groupBy((shift) => shift.get('id'))
    const { id, isPosted, shiftDayId } = this.openShiftEntity

    if (isPosted) {
      return this.openShiftStore.loadShiftOpportunity(id, facilityUsersMap, shiftsById)
    } else {
      return this.openShiftStore.loadOpenShift(shiftDayId, facilityUsersMap, shiftsById)
    }
  }

  async deleteShiftOpportunity() {
    const { facilityUsersMap, unit } = this
    const { shiftDayId } = this.openShiftEntity
    const shifts = unit.get('shifts')
    const shiftsById = shifts.groupBy((shift) => shift.get('id'))

    await this.openShiftStore.deleteShiftOpportunity(this.openShiftEntity.id)

    return this.openShiftStore.loadOpenShift(shiftDayId, facilityUsersMap, shiftsById)
  }

  createShiftOpportunity = (parameters) => {
    const { facilityUsersMap } = this
    const { shiftDayId } = this.openShiftEntity
    return this.openShiftStore.createShiftOpportunity(shiftDayId, parameters, facilityUsersMap)
  }

  updateShiftDayMetadata = async (parameters) => {
    await this.openShiftStore.updateShiftDayMetadata(parameters)
    return this.reloadOpenShift()
  }

  updateShiftOpportunity = async (parameters) => {
    const { facilityUsersMap } = this
    const { id, shiftDayId } = this.openShiftEntity
    const eligibilityType = 'invite'

    await this.openShiftStore.updateShiftOpportunity(
      id,
      {
        ...parameters,
        shiftDayId,
        eligibilityType
      },
      facilityUsersMap
    )
    return this.reloadOpenShift()
  }

  // Not yet implemented:
  update = () => {
    alert('Not yet implemented')
    return Promise.resolve({})
  }

  review = () => {
    alert('Not yet implemented')
    return Promise.resolve({})
  }

  deny = () => {
    alert('Not yet implemented')
    return Promise.resolve({})
  }
}
