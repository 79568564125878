import classNames from 'classnames'
import { t } from 'i18n'
import StaffRow from './StaffRow'
import './StaffSelector.scss'

const StaffSelector = ({ currentStaff, staffList, onSelect }) => {
  const isStaffSelected = !!currentStaff
  return (
    <div>
      <div className="row">
        <div className="form-item col col-3 text-right pr10">
          <label>{t('calendar.staff')}</label>
        </div>
        {!isStaffSelected && (
          <div className="form-item col col-9">
            <input type="text" placeholder="Please Select" disabled />
          </div>
        )}
      </div>
      <div className="row pt5">
        <div className="form-item col col-3" />
        <div className="form-item col col-9">
          {staffList.size === 0 ? (
            <div>{t('calendar.no_staff_for_unit_message')}</div>
          ) : (
            <div className={`staffBox ${classNames({ hideStaffBox: isStaffSelected })}`}>
              {staffList.map((staff) => (
                <StaffRow key={staff.get('id')} staff={staff} handleStaffSelection={() => onSelect(staff)} />
              ))}
            </div>
          )}
          {isStaffSelected && (
            <div className={`staffBox ${classNames({ selectedStaff: isStaffSelected })}`}>
              <StaffRow
                staff={currentStaff}
                removeStaffSelection={() => onSelect(null)}
                isStaffSelected={isStaffSelected}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default StaffSelector
