import { List } from 'immutable'

export default class StaffEligibilityService {
  constructor(timeService) {
    this.timeService = timeService
  }

  getEligibleUnitsForDateRange(startDate, endDate, eligibleUnits) {
    const startDateMoment = this.timeService.timeMoment(startDate)
    const endDateMoment = this.timeService.timeMoment(endDate)

    return eligibleUnits.filter((eligibleUnit) => {
      const eligibleUnitStartsAt = eligibleUnit.get('startsAt')
      const eligibleUnitEndsAt = eligibleUnit.get('endsAt')

      const startDateBeforeEligibleUnitEnds = !eligibleUnitEndsAt || startDateMoment.isBefore(eligibleUnitEndsAt)
      const endDateAfterEligibleUnitStarts = endDateMoment.isAfter(eligibleUnitStartsAt)

      return startDateBeforeEligibleUnitEnds && endDateAfterEligibleUnitStarts
    })
  }

  getClosestEligibleUnit(unitId, eligibleUnits) {
    const now = this.timeService.timeMoment(null)

    return eligibleUnits.reduce((memoEligibleUnit, eligibleUnit) => {
      const homeUnitId = eligibleUnit.get('homeUnitId')

      const searchInCurrentUnit = !!unitId
      const isCurrentUnit = homeUnitId === unitId
      if (searchInCurrentUnit && !isCurrentUnit) {
        return memoEligibleUnit
      }

      if (this._isCurrentEligibleUnit(eligibleUnit)) {
        return eligibleUnit
      }

      if (!memoEligibleUnit) {
        return eligibleUnit
      }

      if (this._isCurrentEligibleUnit(memoEligibleUnit)) {
        return memoEligibleUnit
      }

      const startsAt = eligibleUnit.get('startsAt')
      const startsAtMoment = startsAt && this.timeService.timeMoment(startsAt)

      const memoEligibleUnitStartsAt = memoEligibleUnit.get('startsAt')
      const isEligibleUnitPast = now.isAfter(startsAt)
      const isMemoEligibleUnitPast = now.isAfter(memoEligibleUnitStartsAt)

      if (!isEligibleUnitPast && isMemoEligibleUnitPast) {
        return eligibleUnit
      }

      const isEligibleUnitCloser = isEligibleUnitPast
        ? startsAtMoment.isAfter(memoEligibleUnitStartsAt)
        : startsAtMoment.isBefore(memoEligibleUnitStartsAt)

      if (isEligibleUnitCloser) {
        return eligibleUnit
      }

      return memoEligibleUnit
    }, null)
  }

  getEligibleUnitForDate(eligibleUnits, dateTime) {
    return eligibleUnits?.find((eligibleUnit) => {
      const eligibleUnitStartsAt = eligibleUnit.get('startsAt')
      const eligibleUnitEndsAt = eligibleUnit.get('endsAt')

      const startOfDay = this.timeService.timeMoment(dateTime).startOf('day')

      const isDateAfterEligibleUnitStarts = !eligibleUnitStartsAt || startOfDay.isSameOrAfter(eligibleUnitStartsAt)
      const isDateBeforeEligibleUnitEnds = !eligibleUnitEndsAt || startOfDay.isBefore(eligibleUnitEndsAt)

      return isDateAfterEligibleUnitStarts && isDateBeforeEligibleUnitEnds
    })
  }

  getEligibleUnitIdsForDates(eligibleUnits, dates, availableUnitIds) {
    let allEligibleUnitIds = List()
    const eligibleUnitIdsByDays = dates.map((date) => {
      const eligibleUnitForDate = this.getEligibleUnitForDate(eligibleUnits, date)
      if (!eligibleUnitForDate) {
        return null
      }
      const homeUnitId = eligibleUnitForDate.get('homeUnitId')
      const unitIds = availableUnitIds.includes(homeUnitId) ? availableUnitIds : availableUnitIds.push(homeUnitId)
      allEligibleUnitIds = allEligibleUnitIds.concat(unitIds)
      return unitIds
    })

    // filter uniq values:
    allEligibleUnitIds = allEligibleUnitIds.toSet().toList()

    return allEligibleUnitIds.filter((unitId) => eligibleUnitIdsByDays.every((unitIds) => unitIds.includes(unitId)))
  }

  getCurrentEligibleUnit(eligibleUnits) {
    const now = this.timeService.timeMoment(null)

    return eligibleUnits?.find((eligibleUnit) => {
      const endsAt = eligibleUnit.get('endsAt')
      const startsAt = eligibleUnit.get('startsAt')
      const startsAtMoment = startsAt && this.timeService.timeMoment(startsAt)
      const endsAtMoment = endsAt && this.timeService.timeMoment(endsAt)

      const startsAtBeforeNow = startsAtMoment.isSameOrBefore(now)
      const endsAtAfterNow = !endsAt || endsAtMoment.isAfter(now)

      return endsAtAfterNow && startsAtBeforeNow
    })
  }

  _isCurrentEligibleUnit(eligibleUnit) {
    const now = this.timeService.timeMoment(null)

    const startsAt = eligibleUnit.get('startsAt')
    const endsAt = eligibleUnit.get('endsAt')

    const startsAtMoment = startsAt && this.timeService.timeMoment(startsAt)
    const endsAtMoment = endsAt && this.timeService.timeMoment(endsAt)

    const startsAtBeforeNow = startsAtMoment.isSameOrBefore(now)
    const endsAtAfterNow = !endsAt || endsAtMoment.isAfter(now)

    return endsAtAfterNow && startsAtBeforeNow
  }
}
