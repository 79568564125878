import { Map, List } from 'immutable'
import { PureComponent, Fragment, createRef } from 'react'
import { withAppContext } from 'AppContext'
import './StaffEventInfoTooltip.scss'
import Avatar from 'Manager/components/Avatar'
import { t } from 'i18n'
import { getRemoteStaffData } from 'utils/getRemoteStaffData'
import { remoteServiceUserIds } from 'utils/remoteServiceUserIds'
import { RemoteWorkflowType } from 'stores/remoteWorkflowStore/enums/RemoteWorkflowType'
import { RemoteRequestState } from 'stores/remoteWorkflowStore/enums/RemoteRequestState'

export class StaffEventInfoTooltip extends PureComponent {
  constructor(props) {
    super(props)
    this.cellTooltipRef = createRef()
  }

  componentDidUpdate(prevProps) {
    const { isVisible, cellPosition } = this.props
    if (isVisible && cellPosition !== prevProps.cellPosition) {
      this.forceUpdate()
    }
  }

  render() {
    const { cellPosition, cell, hasSelectionError, meta } = this.props

    if (hasSelectionError || !cell || meta.isShiftViewEventBar || meta.isCell) {
      return null
    }

    const cellStaffEvent = cell.get('staffEvents')?.get(0) || Map()
    const isUnavailable = cellStaffEvent.get('isUnavailable')
    const cellShiftTime = cellStaffEvent.get('shiftTime')
    const isPTO = cellStaffEvent.get('isPTO')
    const isRequestedToWork = cell.get('isRequestedToWork')
    const isAvatarCell = cell.get('isAvatarCell')
    const hasShiftTime = !!cellShiftTime

    const hasStaffEventInfoTooltip =
      isUnavailable || isPTO || hasShiftTime || isUnavailable || isRequestedToWork || isAvatarCell

    if (!hasStaffEventInfoTooltip) {
      return null
    }

    const position = this.position(cellPosition)

    const cellStaffEvents = cell.get('staffEvents')
    let cellStaffEventsForTooltip
    if (meta.isEventBar) {
      const cellStaffEventInEventBar = cellStaffEvents?.find((event) => event.get('id') === meta.identityHash)
      cellStaffEventsForTooltip = [cellStaffEventInEventBar]
    } else {
      cellStaffEventsForTooltip = cellStaffEvents
    }

    return (
      <div
        ref={this.cellTooltipRef}
        className={`hx-shift-staff-cell-tooltip ${isAvatarCell ? 'cell-tooltip-custom' : ''}`}
        style={position}
      >
        <div style={{ bottom: 0, position: 'absolute' }} className="bg-white rounded">
          {!isAvatarCell
            ? cellStaffEventsForTooltip?.map((event) => this.renderEvent(event))
            : this.renderEvent(cellStaffEvent)}
        </div>
      </div>
    )
  }

  renderEvent(cellStaffEvent) {
    const { notes, cell } = this.props
    const isShiftEvent =
      cellStaffEvent?.get('isAssignment') || cellStaffEvent?.get('isMeeting') || cellStaffEvent?.get('isOnCall')
    const isAvatarCellWithStaffDetails = cell?.get('isAvatarCell') && cell?.get('staff')
    const isUnavailable = cellStaffEvent?.get('isUnavailable')
    const isPTO = cellStaffEvent?.get('isPTO')
    const isRequestedToWork = cell?.get('isRequestedToWork')
    const hasNotes = cellStaffEvent?.get('hasNotes')
    const isDummy = cell?.get('isDummy')
    const noteIds = cellStaffEvent?.get('noteIds') || List()
    const notesCollection = noteIds.map((noteId) => notes?.get(noteId)).filter((note) => note)
    const shouldRenderNotes = hasNotes && !isDummy && notesCollection.size > 0
    const isRemoteStaff = remoteServiceUserIds.includes(cell.get('staff'))

    return (
      <ul key={cellStaffEvent.get('id')}>
        {isShiftEvent && this.renderShiftEvent(cellStaffEvent)}
        {isAvatarCellWithStaffDetails && this.renderAvatarCellTooltip(cellStaffEvent)}
        {isPTO && this.renderStaffPaidTimeOff(cellStaffEvent)}
        {isUnavailable && this.renderRequestedDayOff(cellStaffEvent)}
        {isRequestedToWork && this.renderRequestedToWork()}
        {shouldRenderNotes && this.renderNotes(notesCollection)}
        {isRemoteStaff && this.renderRemoteStaffInfo(cellStaffEvent)}
      </ul>
    )
  }

  renderAvatarCellTooltip(cellStaffEvent) {
    const { cell, timeService, facilityUsersMap, otherStaffMap } = this.props

    let staff = facilityUsersMap?.get(cell?.get('staff')) || otherStaffMap?.get(cell?.get('staff'))

    const isRemoteStaff = remoteServiceUserIds.includes(cell.get('staff'))
    let remoteStaffAvatar
    if (!staff && isRemoteStaff) {
      const { staffProfile, avatar } = getRemoteStaffData(cell)
      staff = staffProfile
      remoteStaffAvatar = avatar
    }

    const unitName = staff?.getIn(['staffProfile', 'eligibleUnits', 0, 'homeUnitName'])
    let avatarProfile = Map({
      avatarUrl: null,
      firstName: '',
      lastName: ''
    })

    let fullName

    if (staff) {
      const avatarUrl = staff.getIn(['profile', 'avatarUrl'])
      const firstName = staff.getIn(['facilityProfile', 'firstName'])
      const lastName = staff.getIn(['facilityProfile', 'lastName'])
      avatarProfile = Map({
        avatarUrl,
        firstName,
        lastName
      })

      fullName = `${firstName} ${lastName}`
    }

    const shiftStartTime = timeService.timeMoment(cellStaffEvent.get('startsAt'))?.format('HH:mm')
    const shiftEndTime = timeService.timeMoment(cellStaffEvent.get('endsAt'))?.format('HH:mm')

    return (
      <aside className="profile-wrapper">
        {!isRemoteStaff && <div className="stronger">Primary Unit : {unitName}</div>}
        <div className="custom-wrapper bt1">
          <Avatar profile={avatarProfile}>{remoteStaffAvatar}</Avatar>
          <div>
            <div className="stronger break-word">{fullName}</div>
            {!isRemoteStaff && (
              <div className="stronger">
                <span>{`${shiftStartTime} : ${shiftEndTime}`}</span>
              </div>
            )}
          </div>
        </div>
      </aside>
    )
  }

  renderNotes = (notesCollection) => {
    const { cell, timeService } = this.props

    const note = notesCollection.last()
    const updatedOn = note.get('createdAt') || note.get('updatedAt')
    const lastUpdateTime = timeService.timeMoment(updatedOn).format('MM-D-YY, HH:mm')

    const name = this.getUpdatedBy(cell)
    const lastUpdateAuthor = name ? `by ${name}` : ''

    const lastUpdateInformation = `Updated ${lastUpdateTime} ${lastUpdateAuthor}`
    const id = note.get('id') || ''
    const subject = note.get('subject') || ''
    const text = note.get('text') || ''

    return (
      <div className="hx-notes-container">
        {note && (
          <Fragment key={id}>
            {subject ? (
              <li data-type="note-subject">
                <div className="pl25">{subject}</div>
              </li>
            ) : null}
            {text ? (
              <li data-type="note-text">
                <div className="pl25">{text}</div>
              </li>
            ) : null}
          </Fragment>
        )}
        <li data-type="updated-at-and-author">
          <div className="regent-gray cite">{lastUpdateInformation}</div>
        </li>
      </div>
    )
  }

  getUpdatedBy = (cell) => {
    const { staffManagersMap } = this.props
    const cellStaffEvent = cell.get('staffEvents')?.get(0) || Map()
    const updatedById = cellStaffEvent?.get('updatedBy')
    const manager = staffManagersMap.get(updatedById) || Map()
    const profile = manager.get('profile') || Map()
    const firstName = profile.get('firstName') || ''
    const lastName = profile.get('lastName') || ''
    return `${firstName} ${lastName}`.trim()
  }

  getShift(cellStaffEvent) {
    const { unitShiftsMap, facilityShiftsMap } = this.props
    const staffEventShiftId = cellStaffEvent?.get('shiftId')
    return unitShiftsMap?.get(staffEventShiftId) || facilityShiftsMap?.get(staffEventShiftId) || Map()
  }

  _getIsBlockedByAssignmentFromOtherUnit(shiftId) {
    const { activeDateRange, facilityShiftsMap } = this.props
    const unitId = activeDateRange.get('unitId')

    const shiftIdsForDisplayedUnit = []
    facilityShiftsMap.forEach((shift, shiftId) => {
      if (shift.get('unitId') === unitId) {
        shiftIdsForDisplayedUnit.push(shiftId)
      }
    })

    return !shiftIdsForDisplayedUnit.includes(shiftId)
  }

  renderShiftEvent(cellStaffEvent) {
    const shift = this.getShift(cellStaffEvent)
    const name = shift.get('name')
    const shiftId = shift.get('id')
    const unitName = shift.get('unitName')
    const isBlockedByAssignmentFromOtherUnit = this._getIsBlockedByAssignmentFromOtherUnit(shiftId)
    const shiftTime = cellStaffEvent.get('shiftTime')
    const isAssignment = !!cellStaffEvent.get('isAssignment')
    const isMeeting = cellStaffEvent.get('isMeeting')
    const isUnavailable = cellStaffEvent.get('isUnavailable')
    const isPTO = cellStaffEvent.get('isPTO')
    const isOnCall = cellStaffEvent.get('isOnCall')
    const isCancelled = !!cellStaffEvent.get('isCancelled')
    const isPrimaryShift = !!cellStaffEvent.get('isPrimaryShift')
    const isPartialShiftEvent = !!cellStaffEvent.get('isPartialShiftEvent')
    const isExpertiseMismatchEvent = !!cellStaffEvent.get('isExpertiseMismatchEvent')
    const isTimeOff = isUnavailable || isPTO

    let icon = ''
    if (isAssignment) {
      icon = this.getIsAssignmentIcon(isCancelled, isPrimaryShift, isPartialShiftEvent, isExpertiseMismatchEvent)
    }

    if (isOnCall) {
      icon = 'icon-indicator-call'
    }

    if (isMeeting) {
      icon = 'new-icon-event'
    }

    if (isBlockedByAssignmentFromOtherUnit && !(isTimeOff || isMeeting)) {
      icon = 'icon-circle'
    }

    return (
      <>
        <div className="p5" data-type="assignment">
          {!isMeeting && (
            <div className="flex align-middle">
              <span className="pr5">
                <i className={`hx-staff-event-info-icon large ${icon}`} />
              </span>
              <span className="stronger">{unitName}</span>
            </div>
          )}
          <div className="river-bad stronger">
            <div className="flex align-middle">
              {isMeeting ? (
                <span>
                  <i className={`hx-staff-event-info-icon large ${icon} `} />
                </span>
              ) : (
                ''
              )}
              {isMeeting ? 'Event' : name || ''}: {shiftTime}
            </div>
          </div>
        </div>
      </>
    )
  }

  renderStaffPaidTimeOff(cellStaffEvent) {
    const shiftTime = cellStaffEvent.get('shiftTime')
    const isPartialShiftEvent = !!cellStaffEvent.get('isPartialShiftEvent')
    const icon = isPartialShiftEvent ? 'icon-partial-shift' : 'icon-Money-Sign'
    return (
      <>
        <li data-type="staff-paid-time-off">
          <span>
            <i className={`hx-staff-event-info-icon large ${icon}`} />
          </span>
          <div className="river-bad stronger">&nbsp;PTO: {shiftTime}</div>
        </li>
      </>
    )
  }

  renderWescanRemoteStaffInfo(remoteStaffRequestAttributes) {
    const { timeService } = this.props
    const wescan = remoteStaffRequestAttributes?.getIn(['requestDetails', 'wescan'])
    return (
      <>
        <li data-type="staff-wescan-request-working-hours">
          {t('equipments.wescan_tool_tip.working_hours')}:
          <ul>
            {wescan?.get('appointments')?.map((appointment, index) => {
              const state = appointment.get('state')
              if (state !== RemoteRequestState.Assigned && state !== RemoteRequestState.Closed) {
                return
              }

              const shiftStartTime = timeService.timeMoment(appointment.get('startsAt'))?.format('HH:mm')
              const shiftEndTime = timeService.timeMoment(appointment.get('endsAt'))?.format('HH:mm')

              return (
                <li key={index}>
                  {shiftStartTime} - {shiftEndTime}
                </li>
              )
            })}
          </ul>
        </li>
        <li data-type="staff-wescan-request-status">
          {t('equipments.wescan_tool_tip.status')}: {remoteStaffRequestAttributes?.get('state')}
        </li>
        <li data-type="staff-wescan-request-procedure-type">
          {t('equipments.wescan_tool_tip.procedure_type')}: {wescan?.get('procedures')?.join(', ')}
        </li>
        <li data-type="staff-wescan-request-service-type">
          {t('equipments.wescan_tool_tip.service_type')}: {wescan?.get('serviceType')}
        </li>
      </>
    )
  }

  renderRemoteStaffInfo(cellStaffEvent) {
    const remoteStaffRequestAttributes = cellStaffEvent.get('remoteStaffRequestAttributes')
    //eslint-disable-next-line sonarjs/no-small-switch
    switch (remoteStaffRequestAttributes.get('remoteWorkflowType')) {
      case RemoteWorkflowType.WeScan:
        return this.renderWescanRemoteStaffInfo(remoteStaffRequestAttributes)
      default:
        return {}
    }
  }

  getIsAssignmentIcon = (isCancelled, isPrimaryShift, isPartialShiftEvent, isExpertiseMismatchEvent) => {
    let icon = ''

    if (isCancelled) {
      icon = 'new-icon-cancelled-shift'
    } else if (isPartialShiftEvent) {
      icon = 'icon-partial-shift'
    } else if (isExpertiseMismatchEvent) {
      icon = 'icon-expertise-mismatch'
    } else {
      icon = 'icon-dot'
    }

    return icon
  }

  renderRequestedDayOff(cellStaffEvent) {
    const shiftTime = cellStaffEvent.get('shiftTime')
    const isPartialShiftEvent = !!cellStaffEvent.get('isPartialShiftEvent')
    const icon = isPartialShiftEvent ? 'icon-partial-shift' : 'icon-close'
    return (
      <>
        <li data-type="requested-day-off">
          <div className="flex">
            <span>
              <i className={`hx-staff-event-info-icon large ${icon}`} />
            </span>
            <div className="river-bad stronger">&nbsp;Requested Day Off: {shiftTime}</div>
          </div>
        </li>
      </>
    )
  }

  renderRequestedToWork() {
    return (
      <li data-type="requested-to-work">
        <span>
          <i className="icon-checkmark" />
        </span>
        <div className="river-bad">Available</div>
      </li>
    )
  }

  position(cellPosition) {
    const tooltipReference = this.cellTooltipRef.current
    let tooltipWidth = 180

    if (tooltipReference !== null) {
      ;({ width: tooltipWidth } = tooltipReference.getBoundingClientRect())

      tooltipWidth = Math.max(180, tooltipWidth)
    }

    const { left, top } = cellPosition

    return {
      position: 'fixed',
      left: `${left - tooltipWidth}px`,
      top: `${top}px`,
      height: 0
    }
  }
}

export default withAppContext(StaffEventInfoTooltip)
