import { PureComponent } from 'react'
import { PopUp } from 'Manager/components'
import Item from './Item'
import ShiftsSubmenu from './ShiftsSubmenu'
import TimeOffsSubmenu from './TimeOffsSubmenu'
import { pick } from 'lodash'
import classNames from 'classnames'
import './StaffViewCellContextMenu.scss'
import { FacilityUserShiftsService, TimeOffDefinitionsService } from 'services'
import { Calendar, Staff } from 'entityWrappers'
import {
  getStaffEventIndex,
  hasFullDayOff,
  hasPTO,
  hasUnavailable,
  hasTimeOff,
  hasShiftEvent
} from '../CellDetails/cellDetailsUtils'
import { addWarningBanner } from '@humanics/he-react-common/lib/stores/bannersStore'
import { t } from 'i18n'

// TODO refactor to have context menu component independent from data
export default class StaffViewCellContextMenu extends PureComponent {
  constructor(props) {
    super(props)
    this.state = { lockedSubmenu: false }
  }

  componentDidUpdate(prevProps) {
    const { selection } = this.props
    const { selection: prevSelection } = prevProps

    const isSelectionChanged = selection !== prevSelection
    if (isSelectionChanged) {
      this.hideSubmenu()
    }
  }

  isAddShiftShown(hasShiftEvent, hasFullDayOff) {
    return !hasShiftEvent && !hasFullDayOff
  }

  isAddTimeOffShown(canDisplayTimeOff, hasShiftEvent, hasPTO, hasUnavailable) {
    return canDisplayTimeOff && !(hasPTO || hasUnavailable) && !hasShiftEvent
  }

  isChangeRemoveTimeOffShown(canDisplayTimeOff, hasPTO, hasUnavailable) {
    return canDisplayTimeOff && (hasPTO || hasUnavailable)
  }

  isShiftsSubmenuShown(isAddShiftSubmenu, shownSubmenuId) {
    return isAddShiftSubmenu || shownSubmenuId === 'changeShift'
  }

  isAddTimeOffSubmenu(isAddTimeOffShown, shownSubmenuId) {
    return isAddTimeOffShown && shownSubmenuId === 'addTimeOff'
  }

  isTimeOffsSubmenuShown(isAddTimeOffSubmenu, shownSubmenuId) {
    return isAddTimeOffSubmenu || shownSubmenuId === 'changeTimeOff'
  }

  shiftsSubmenuClickHandler = (shift, staffEventId) => {
    return staffEventId ? () => this.deleteStaffEvent(staffEventId) : () => this.addShift(shift)
  }

  timeoffsSubmenuClickHandler = (args, isAddTimeOffSubmenu) => {
    return isAddTimeOffSubmenu ? () => this.addTimeOff(...args) : () => this.changeTimeOff(...args)
  }

  showAssignmentOverlapError = () => {
    const { staff } = this.getSelectedObjects()
    const errorMsg = t('errors.assignment_overlap', { name: staff?.get('fullName') })
    addWarningBanner({ message: errorMsg })
  }

  render() {
    const {
      scrollTop,
      popupProps,
      showCellDetailsPopup,
      unit,
      timeService,
      calendarStore,
      unitManager,
      isCellContextMenuVisible
    } = this.props

    if (!isCellContextMenuVisible) {
      if (this.state.lockedSubmenu) {
        this.setState({ lockedSubmenu: false })
      }
      return null
    }

    const { staff, cell, day, cellSubIndex } = this.getSelectedObjects()

    if (cell.get('isAvatarCell')) {
      showCellDetailsPopup()
      return null
    }
    const calendar = new Calendar(this.props.calendar, calendarStore)
    const { shifts } = calendar

    const startsAt = day.get('dateTime')
    const managerOtherUnitIds = unitManager.getIn(['unitManagerProfile', 'otherUnitIds'])
    const managerHomeUnitId = unitManager.getIn(['unitManagerProfile', 'homeUnitId'])
    const availableUnitIds = managerOtherUnitIds.push(managerHomeUnitId)

    const userShiftsService = new FacilityUserShiftsService(timeService)
    const { primaryShift, secondaryShifts, onCallShifts } = userShiftsService.getShiftsForDate(
      startsAt,
      staff,
      shifts,
      availableUnitIds
    )

    const staffEventIndex = getStaffEventIndex(cell, 'timeOff')
    const cellStaffEvent = cell.get('staffEvents')?.get(staffEventIndex)
    const staffEventId = cellStaffEvent?.get('id')

    const unitId = unit.get('id')
    const user = new Staff(staff)
    const homeUnitId = user.getHomeUnitIdForDate(startsAt, timeService)
    const isHomeUnit = homeUnitId === unitId
    const isSecondaryStaff = staff.get('shiftId') === 'secondary-staff-shift'
    const canDisplayTimeOff = isHomeUnit && !isSecondaryStaff

    const isChangeShiftShown = hasShiftEvent(cell)
    const isAddShiftShown = this.isAddShiftShown(hasShiftEvent(cell), hasFullDayOff(cell))
    const isShiftMenuVisible =
      isAddShiftShown ||
      //  isRemoveShiftShown ||
      isChangeShiftShown

    const isAddTimeOffShown = this.isAddTimeOffShown(
      canDisplayTimeOff,
      hasShiftEvent(cell),
      hasPTO(cell),
      hasUnavailable(cell)
    )

    const isChangeTimeOffShown = this.isChangeRemoveTimeOffShown(canDisplayTimeOff, hasPTO(cell), hasUnavailable(cell))
    const isRemoveTimeOffShown = this.isChangeRemoveTimeOffShown(canDisplayTimeOff, hasPTO(cell), hasUnavailable(cell))
    const isTimeOffVisible = isAddTimeOffShown || isRemoveTimeOffShown || isChangeTimeOffShown

    if (!isTimeOffVisible && !isShiftMenuVisible) {
      return null
    }

    const { shownSubmenuId } = this.state
    const isAddShiftSubmenu = shownSubmenuId === 'addShift'
    const isShiftsSubmenuShown = this.isShiftsSubmenuShown(isAddShiftSubmenu, shownSubmenuId)

    const isAddTimeOffSubmenu = this.isAddTimeOffSubmenu(isAddTimeOffShown, shownSubmenuId)
    const isTimeOffsSubmenuShown = this.isTimeOffsSubmenuShown(isAddTimeOffSubmenu, shownSubmenuId)

    const eventVariants = unit.get('eventVariants')
    const shiftsSubmenuProps = pick(
      {
        ...this.state,
        ...this.props,
        eventVariants,
        primaryShift,
        secondaryShifts,
        onCallShifts,
        cellSubIndex,
        cell
      },
      ['popupProps', 'primaryShift', 'secondaryShifts', 'eventVariants', 'cellSubIndex', 'onCallShifts', 'cell']
    )

    shiftsSubmenuProps.onClickHandler = (...args) => this.shiftsSubmenuClickHandler(...args)

    const timeOffEventVaraints = eventVariants.filter((variant) => variant.get('type') === 'timeOff')
    const timeoffsSubmenuProps = { popupProps, timeOffEventVaraints }

    timeoffsSubmenuProps.onClickHandler = (...args) => this.timeoffsSubmenuClickHandler(args, isAddTimeOffSubmenu)

    const shiftTitle = isAddShiftShown ? 'Add Shift' : 'Change Shift'

    return (
      <PopUp {...popupProps} scrollTop={scrollTop} onBlur={this.hide}>
        <div
          data-testid="hx-calendar-staff-view-cell-context-menu"
          className="context hx-calendar-context-menu"
          tabIndex="1"
          ref={(ref) => {
            this.ref = ref
          }}
        >
          {(isAddShiftShown || isChangeShiftShown) && (
            <Item
              title={shiftTitle}
              hasSubmenu={true}
              className={classNames({
                selected: isShiftsSubmenuShown
              })}
              onMouseEnter={(e) => this.showSubmenu('addShift')}
              onClick={(e) => {
                this.lockSubmenu('addShift')
              }}
            />
          )}

          {isAddTimeOffShown && (
            <Item
              title="Add Time Off"
              hasSubmenu={true}
              className={classNames({
                selected: isAddTimeOffSubmenu
              })}
              onMouseEnter={(e) => this.showSubmenu('addTimeOff')}
              onClick={(e) => {
                this.lockSubmenu('addTimeOff')
              }}
            />
          )}

          {/* {isChangeShiftShown && (
            <Item
              title="Change Shift"
              hasSubmenu={true}
              className={classNames({
                selected: isShiftsSubmenuShown
              })}
              onMouseEnter={e => this.showSubmenu('changeShift')}
            />
          )} */}

          {isChangeTimeOffShown && (
            <Item
              title="Change Time Off"
              hasSubmenu={true}
              className={classNames({
                selected: isTimeOffsSubmenuShown
              })}
              onMouseEnter={(e) => this.showSubmenu('changeTimeOff')}
            />
          )}

          {/* {isRemoveShiftShown && (
            <Item
              title="Remove Shift"
              onMouseEnter={this.hideSubmenu}
              onClick={this.deleteStaffEvent}
            />
          )} */}

          {isRemoveTimeOffShown && (
            <Item
              title="Remove Time Off"
              onMouseEnter={this.hideSubmenu}
              onClick={() => this.deleteStaffEvent(staffEventId)}
            />
          )}

          {
            <Item
              title="Advanced Options"
              className="bg-athensgray hx-contex-menu-item-advanced-options"
              onMouseEnter={this.hideSubmenu}
              iconClassName="humanics-icons-Note"
              onClick={showCellDetailsPopup}
              isAdvanceOption={true}
            />
          }

          {isShiftsSubmenuShown && <ShiftsSubmenu {...shiftsSubmenuProps} />}

          {isTimeOffsSubmenuShown && <TimeOffsSubmenu {...timeoffsSubmenuProps} />}
        </div>
      </PopUp>
    )
  }

  showSubmenu = (shownSubmenuId) => {
    if (!this.state.lockedSubmenu) {
      this.setState({ shownSubmenuId })
    }
  }
  hideSubmenu = () => {
    if (!this.state.lockedSubmenu) {
      this.setState({ shownSubmenuId: false })
    }
  }
  lockSubmenu = (submenuId) => {
    this.setState({ lockedSubmenu: true, shownSubmenuId: submenuId })
  }

  getSelectedObjects() {
    const { calendarStore, selection } = this.props

    const calendar = new Calendar(this.props.calendar, calendarStore)
    const staffPath = selection.get('staffPath')
    const cellIndex = selection.get('cells').findLastKey(() => true)
    const details = selection.getIn(['cells', cellIndex])
    const { cellSubIndex } = details
    const cellDetails = calendar.getCellDetails(staffPath, cellIndex)
    return {
      ...cellDetails,
      cellSubIndex
    }
  }

  addShift = (eventParameters) => {
    const { createStaffEventsForSelection } = this.props

    createStaffEventsForSelection(eventParameters)
  }

  deleteStaffEvent = async (staffEventId) => {
    const { deleteSelectedStaffEvents } = this.props

    deleteSelectedStaffEvents(staffEventId)
  }

  addTimeOff = async (eventVariant, subject) => {
    const { cell } = this.getSelectedObjects()
    const { addTimeOffs, hideContextMenu } = this.props
    const { isPartial } = TimeOffDefinitionsService.getDefaultTimeOffAttributes(subject)
    const note = subject && { subject, text: '' }

    if (hasShiftEvent(cell)) {
      this.showAssignmentOverlapError()
    } else {
      addTimeOffs(eventVariant, note, undefined, undefined, isPartial)
    }
    hideContextMenu()
  }

  changeTimeOff = async (eventVariant, subject) => {
    const { cell } = this.getSelectedObjects()
    const { changeTimeOffs, hideContextMenu } = this.props
    const note = subject && { subject, text: '' }

    if (hasShiftEvent(cell) && hasTimeOff(cell)) {
      // changes partial time-off to full-day in this case
      this.showAssignmentOverlapError()
    } else {
      changeTimeOffs(eventVariant, note)
    }
    hideContextMenu()
  }
}
