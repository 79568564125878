import moment from 'moment'

export default function getEndTimeForShift(shift) {
  const startTime = shift.startTime
  const length = shift.length

  const start = moment(startTime, 'HH:mm')
  const end = start.clone().add(length, 'minutes')

  return end.format('HH:mm')
}
