import { fromJS, Map } from 'immutable'
import { OpenShiftsService } from 'services'
import updateOpenShift from '../../updateOpenShift'
import { uniq } from 'lodash'
import getStaffAssignedWeekHoursMap from '../getStaffAssignedWeekHoursMap'
import { openShiftQuery, staffEventsQuery } from '../../Queries'
import {
  approveShiftOpportunityStaffResponseMutation,
  createShiftOpportunityMutation,
  createStaffEventsMutation,
  deleteShiftOpportunityMutation,
  updateShiftOpportunityMutation,
  updateShiftDayMetadataMutation
} from '../../Mutations'
import { addWarningBanner } from '@humanics/he-react-common/lib/stores/bannersStore'

function OpenShiftActions() {
  let gqlClient, timeService, updateOpenShiftsStateWith
  const actions = {
    createStaffEvent,
    approveStaffResponse,
    deleteShiftOpportunity,
    createShiftOpportunity,
    updateShiftOpportunity,
    updateShiftDayMetadata
  }

  return { initialize, actions }

  function initialize(context) {
    ;({ gqlClient, facilityTime: timeService, updateOpenShiftsStateWith } = context)
  }

  async function updateShiftDayMetadata(parameters) {
    const { shiftDayId, isImportant } = parameters
    const params = {
      id: shiftDayId,
      parameters: {
        isImportant
      }
    }
    return gqlClient.mutate(updateShiftDayMetadataMutation, params)
  }

  async function createShiftOpportunity(shiftDayId, openShift, facilityUsersMap) {
    const isFilled = await isOpenShiftFilled(shiftDayId)
    if (isFilled) {
      addWarningBanner(errorMessage(ALREADY_FILLED))
      return Promise.reject({ message: ALREADY_FILLED })
    }

    const { acceptanceCriteria, eligibleUserIds, isBonusPay, isImportant, note } = openShift

    const parameters = {
      shiftDayId,
      acceptanceCriteria,
      incentiveBonus: isBonusPay,
      isImportant,
      parameters: { eligibleUserIds, note: { title: '', text: note } }
    }

    const { createShiftOpportunity: shiftOpportunity } = await gqlClient.mutate(
      createShiftOpportunityMutation,
      parameters
    )
    const { availableStaff } = shiftOpportunity

    return extendShiftOpportunity(shiftOpportunity, availableStaff, facilityUsersMap)
  }

  async function updateShiftOpportunity(id, { shiftDayId, eligibleUserIds }, facilityUsersMap) {
    const parameters = { id, eligibleUserIds }

    const { shiftOpportunity } = await gqlClient.mutate(updateShiftOpportunityMutation, parameters)
    const { availableStaff } = shiftOpportunity

    return extendShiftOpportunity(shiftOpportunity, availableStaff, facilityUsersMap)
  }

  function deleteShiftOpportunity(id) {
    return gqlClient.mutate(deleteShiftOpportunityMutation, { id })
  }

  async function approveStaffResponse(id, userId, facilityUsersMap) {
    const { approveShiftOpportunityStaffResponse: shiftOpportunity } = await gqlClient.mutate(
      approveShiftOpportunityStaffResponseMutation,
      {
        id,
        parameters: { userId }
      }
    )

    const { shiftDayId, availableStaff } = shiftOpportunity

    const { staffEvents } = await gqlClient.query(staffEventsQuery, {
      shiftDayId,
      types: ['assignment'],
      limit: 99999
    })

    shiftOpportunity.approvedUserIds = uniq(staffEvents.map((staffEvent) => staffEvent.userId))

    return extendShiftOpportunity(shiftOpportunity, availableStaff, facilityUsersMap)
  }

  async function extendShiftOpportunity(shiftOpportunity, openShiftFacilityUsers, facilityUsersMap) {
    const { shiftStartsAt: date, eligibleUserIds } = shiftOpportunity
    const availableUserIds = openShiftFacilityUsers.map(({ userId }) => userId)
    const facilityUserIds = uniq(availableUserIds.concat(eligibleUserIds))
    const staffAssignedWeekHoursMap = await getStaffAssignedWeekHoursMap(gqlClient, date, facilityUserIds)

    const extensionParameters = {
      availableUserIds,
      facilityUserIds,
      facilityUsersMap,
      staffAssignedWeekHoursMap
    }
    const openShiftsService = new OpenShiftsService(timeService)
    const shiftOpprtunityExtended = openShiftsService.extendOpenShift(
      Map(),
      fromJS(shiftOpportunity),
      extensionParameters
    )

    return updateOpenShift(updateOpenShiftsStateWith, shiftOpprtunityExtended, timeService, true)
  }

  async function isOpenShiftFilled(shiftDayId) {
    const params = { shiftDayId }
    const { openShift } = await gqlClient.query(openShiftQuery, params)
    return openShift && openShift.staffMismatch <= 0
  }

  function createStaffEvent({ userId, unitId, shiftId, startsAt, duration }) {
    const staffEvent = { userId, unitId, shiftId, startsAt, duration }
    const staffEvents = [staffEvent]
    const parameters = {
      parameters: {
        staffEvents,
        unitEventVariantId: 'assignment',
        omitExpertiseVerification: true
      }
    }
    return gqlClient.mutate(createStaffEventsMutation, parameters)
  }
}

const errorMessage = (message) => ({ message })
const ALREADY_FILLED = `Shift has been already filled.
Go to the Filled Open Shifts or Calendar View to see details.`

export default OpenShiftActions()
