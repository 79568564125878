import classNames from 'classnames'
import { List } from 'immutable'
import { isEqual } from 'lodash'
import { useEffect, useState } from 'react'
import { navigate } from 'Navigation'
import { getStaffStrategyProperties } from 'utils'
import './ShiftDayRequirement.scss'

const ShiftDayRequirement = (props) => {
  const [isEditable, setIsEditable] = useState(false)
  const [dayDetails, setDayDetails] = useState({
    startTime: '',
    endTime: '',
    minTargetCover: 0,
    maxTargetCover: 0,
    allowableGap: 0
  })
  const [isDataChanged, setIsDataChanged] = useState(false)
  const [error, setError] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [updatingTimeout, setUpdatingTimeout] = useState(0)

  const { calendar, filters, openShiftPopupProps, timeService } = props
  const { roleIndex, shiftIndex, dayIndex } = openShiftPopupProps
  const shiftPath = ['roles', roleIndex, 'shifts', shiftIndex]
  const shiftDay = calendar.getIn([...shiftPath, 'shiftDays', dayIndex])
  const staffScheduled = shiftDay.get('coverage')
  const startTime = timeService.timeMoment(shiftDay.get('startsAt'))?.format('HH:mm')
  const duration = shiftDay.get('duration')
  const endTime = timeService.timeMoment(shiftDay.get('startsAt')).add(duration, 'minutes').format('HH:mm')
  const isWorking = shiftDay.get('isWorking')
  const minimumCoverageRequired = shiftDay.get('minimumCoverageRequired')
  const maximumCoverageRequired = shiftDay.get('maximumCoverageRequired')
  const allowableGap = shiftDay.get('allowableGap')

  useEffect(() => {
    if (shiftDay) {
      setIsEditable(isWorking)
      setDayDetails({
        startTime: startTime,
        endTime: endTime,
        minTargetCover: minimumCoverageRequired,
        maxTargetCover: maximumCoverageRequired,
        allowableGap: allowableGap
      })
    }
  }, [])

  useEffect(() => {
    setIsDataChanged(
      !isEqual(dayDetails, {
        startTime: startTime,
        endTime: endTime,
        minTargetCover: minimumCoverageRequired,
        maxTargetCover: maximumCoverageRequired,
        allowableGap: allowableGap
      }) || isWorking !== isEditable
    )
  }, [
    dayDetails,
    isEditable,
    startTime,
    minimumCoverageRequired,
    maximumCoverageRequired,
    allowableGap,
    isWorking,
    endTime
  ])

  useEffect(() => {
    if (isEditable === isWorking) {
      setDayDetails({
        startTime: startTime,
        endTime: endTime,
        minTargetCover: minimumCoverageRequired,
        maxTargetCover: maximumCoverageRequired,
        allowableGap: allowableGap
      })
    }
  }, [allowableGap, isEditable, isWorking, maximumCoverageRequired, minimumCoverageRequired, startTime, endTime])

  const handleCheckboxChange = () => {
    setIsEditable((prev) => !prev)
  }

  const _convertCamelCaseToTitleCase = (str) => {
    return str.replace(/([A-Z])/g, ' $1').toLowerCase()
  }

  const handleChange = (event) => {
    const { name, value } = event.target
    setError('')
    if (isEditable && name === 'minTargetCover' && value > dayDetails.maxTargetCover) {
      setError('min target cover cannot be greater than max target cover')
    }
    if (isEditable && name === 'maxTargetCover' && value < dayDetails.minTargetCover) {
      setError('max target cover cannot be less than min target cover')
    }
    if (isEditable && ['allowableGap', 'minTargetCover', 'maxTargetCover'].includes(name) && value < 0) {
      const field = _convertCamelCaseToTitleCase(name)
      setError(`${field} cannot be less than 0`)
    }
    if (isEditable && !value) {
      const field = _convertCamelCaseToTitleCase(name)
      setError(`${field} field is required`)
    }
    if (isEditable && name === 'allowableGap' && value % 15 !== 0) {
      setError('Allowable gap should be in multiples of 15 mins')
    }
    setDayDetails((prevData) => ({
      ...prevData,
      [name]: value ? parseInt(value) : ''
    }))
  }

  const strategy = filters.getIn(['displayOptions', 'coverage', 'viewCoverageAs'])
  const { staffDelta, className, isBalanced, isUnderstaffed } = getStaffStrategyProperties(strategy, staffScheduled, {
    min: dayDetails.minTargetCover,
    max: dayDetails.maxTargetCover
  })

  const updateShiftDayRequirement = (e) => {
    e.preventDefault()
    const { reloadCalendarForShift, calendarStore } = props
    const shift = calendar.getIn(['roles', roleIndex, 'shifts', shiftIndex])
    const date = calendar.getIn(['days', dayIndex, 'dateTime'])

    if (isLoading) {
      return
    }

    if (updatingTimeout) {
      clearTimeout(updatingTimeout)
    }

    if (!shift || !date || !isDataChanged) {
      return
    }

    const userIds = shift
      .get('staff')
      .map((s) => s.get('id'))
      .toArray()

    let params
    if (isEditable) {
      params = {
        shiftDayId: shiftDay?.get('id'),
        isWorking: isEditable,
        minimumCoverageRequired: dayDetails.minTargetCover,
        maximumCoverageRequired: dayDetails.maxTargetCover,
        allowableGap: dayDetails.allowableGap
      }
    } else {
      params = {
        shiftDayId: shiftDay?.get('id'),
        isWorking: isEditable || false
      }
    }

    setUpdatingTimeout(
      setTimeout(async () => {
        setIsLoading(true)
        await calendarStore.updateShiftDayMetadata(params)
        await reloadCalendarForShift({ shift, userIds })
        setIsLoading(false)
      }, 500)
    )
  }

  const renderFooter = () => {
    let staffEventUserIds = List()

    const shiftStaff = calendar.getIn([...shiftPath, 'staff'])

    shiftStaff?.forEach((staff) => {
      const staffId = staff.get('id')
      const staffEventId = staff.getIn(['cells', dayIndex, 'staffEventId'])
      if (staffEventId) {
        staffEventUserIds = staffEventUserIds.push(staffId)
      }
    })

    const shiftDayId = shiftDay?.get('id')
    const id = shiftDay?.getIn(['opportunity', 'id'])
    const acceptedByUserIds = shiftDay?.getIn(['opportunity', 'acceptedByUserIds']) || List()

    const isPendingApproval = acceptedByUserIds.filter((userId) => !staffEventUserIds.includes(userId)).size > 0
    const isPosted = !!id

    let statusTitle
    let buttonTitle

    switch (true) {
      case isUnderstaffed && !isPosted:
        buttonTitle = 'Manage Need'
        break

      case isUnderstaffed && isPosted && !isPendingApproval:
        statusTitle = 'Open Shift Posted'
        buttonTitle = 'Manage Need'
        break

      case isUnderstaffed && isPosted && isPendingApproval:
        statusTitle = 'Needs Approval'
        buttonTitle = 'Manage Need'
        break

      default:
        buttonTitle = 'Review'
    }

    const onlyApprovalPending = isUnderstaffed && isPosted && isPendingApproval

    const status = (
      <div
        className={classNames({
          'pr10 smaller': true,
          hide: !statusTitle,
          sun: isUnderstaffed && isPosted && !isPendingApproval,
          cornflowerblue: isUnderstaffed && isPosted && isPendingApproval
        })}
      >
        {statusTitle}
      </div>
    )

    return (
      <footer className="row align-right align-middle bt1 p10">
        {status}

        <sh-button
          color={onlyApprovalPending ? 'primary' : 'tertiary'}
          onClick={() => navigateToOpenShift({ id, shiftDayId })}
          label={buttonTitle}
          size="auto"
        />
      </footer>
    )
  }

  const navigateToOpenShift = ({ id, shiftDayId }) => {
    const isPosted = !!id

    if (isPosted) {
      navigate.from.Calendar.to.ShiftOpportunity({ id })
    } else {
      navigate.from.Calendar.to.OpenShift({ shiftDayId })
    }
  }

  return (
    <>
      <div className="shift-day-requirement">
        <div className="row p10 pb0">
          <div className="col-6 text-middle">
            <label>
              <span className="form-label">Scheduled Staff</span>
            </label>
          </div>
          <div className="col-6 text-center">
            <div className={classNames('hx-calendar', strategy === 'scheduledStaff' && className)}>
              {staffScheduled}
            </div>
          </div>
        </div>
        {!isBalanced && (
          <div className="row p10">
            <hr className="col-12" />
            <div className="col-6 text-middle">
              <label>
                <span className="form-label">{isUnderstaffed ? 'Understaffed' : 'Overstaffed'}</span>
              </label>
            </div>
            <div className="col-6 text-center">
              <div className={classNames('hx-calendar', strategy === 'staffDelta' && className)}>{staffDelta}</div>
            </div>
          </div>
        )}
        <form>
          <div className="row ml10 p10 pl0">
            <input type="checkbox" id="editable" checked={isEditable} onChange={handleCheckboxChange} />
            <label htmlFor="editable" className="text-center">
              &nbsp; is operational
            </label>
          </div>

          <div className="row around pb10">
            <div className="col-5">
              <label>Start Time:</label>
              <input type="time" name="startTime" value={dayDetails.startTime} disabled={true} />
            </div>
            <div className="col-5">
              <label>End Time:</label>
              <input type="time" name="endTime" value={dayDetails.endTime} disabled={true} />
            </div>
          </div>
          <label className="target-cover-title"> Target Cover:</label>
          <div className="row around pb10">
            <div className="col-5">
              <label>Min:</label>
              <input
                type="number"
                min={0}
                name="minTargetCover"
                value={dayDetails.minTargetCover}
                disabled={!isEditable}
                onChange={handleChange}
              />
            </div>
            <div className="col-5">
              <label>Max:</label>
              <input
                type="number"
                min={dayDetails.minTargetCover}
                max={Number.POSITIVE_INFINITY}
                name="maxTargetCover"
                value={dayDetails.maxTargetCover}
                disabled={!isEditable}
                onChange={handleChange}
              />
            </div>
          </div>

          <div className="row pb10">
            <div className="ml10 col-12">
              <label>Allowable Gap:</label>
              <input
                type="number"
                name="allowableGap"
                value={dayDetails.allowableGap}
                disabled={!isEditable}
                min={0}
                step={15}
                onChange={handleChange}
                className="col-5"
              />
            </div>
          </div>
          {error && <div className="row ml10 pb10 red-orange">*{error}*</div>}
          <div className="row align-right pr10">
            <button
              className="button primary"
              disabled={error || !isDataChanged}
              onClick={(e) => {
                updateShiftDayRequirement(e)
              }}
            >
              Save
            </button>
          </div>
        </form>
      </div>
      {renderFooter()}
    </>
  )
}

export default ShiftDayRequirement
