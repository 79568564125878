import { Component } from 'react'
import { Map } from 'immutable'
import { navigate } from 'Navigation'
import ScheduleWorkflow from './ScheduleWorkflow'
import { capitalize, pick } from 'lodash'
import './Navigation.scss'
import MismatchCount from './MismatchCount'
import stores from 'stores'
import { t } from 'i18n'

const { calendarStore } = stores

export default class Navigation extends Component {
  state = { sendingReminder: false }

  sendReminderClick = (e) => {
    const { activeDateRange: range, Dialog } = this.props
    const parameters = {
      scheduleId: range.get('scheduleId'),
      userIds: range.get('facilityUserIds').toArray()
    }

    this.setState({ sendingReminder: true }, async () => {
      try {
        const response = await Dialog.confirm(t('calendar.workflow.send_reminder.disclosure'), {
          type: 'warning',
          accept: t('calendar.workflow.send_reminder.confirm'),
          title: t('calendar.workflow.send_reminder.title')
        })

        if (!response) {
          this.setState({ sendingReminder: false })
          return
        }

        await calendarStore.createStaffUnavailabilityReminders(parameters)
        this.resetReminder = setTimeout(() => this.setState({ sendingReminder: false }), 15000)
      } catch {
        this.setState({ sendingReminder: false })
      }
    })
  }

  componentWillUnmount() {
    clearTimeout(this.resetReminder)
  }

  render() {
    const { activeDateRange, calendar, isShiftDaysLoaded, unit, mode, isEquipmentOrSubspecialityView } = this.props
    const scheduleState = activeDateRange.get('scheduleState')
    const scheduleId = activeDateRange.get('scheduleId')
    const scheduleMetadata = unit.getIn(['schedulesMetadataMap', scheduleId]) || Map()

    const complitedUnavailabilityRequestsCount = scheduleMetadata.get('staffUnavailabilityRequestsSubmittedNumber') || 0
    const staffUnavailabilityRequestsNumber = scheduleMetadata.get('staffUnavailabilityRequestsNumber') || 0

    const scheduleWorkflowProps = pick(this.props, [
      'updateJobProcessingState',
      'generalStore',
      'updateMutedState',
      'activeDateRange',
      'calendarStore',
      'timeService',
      'unit',
      'sendSchedulePdf'
    ])

    const isDateRangeOpen = scheduleState === 'open'
    const isDateRangeInactive = !scheduleState || scheduleState === 'inactive'

    const overstaffCount = calendar.get('overstaffCount')
    const understaffCount = calendar.get('understaffCount')
    const mismatchCountProps = { overstaffCount, understaffCount }

    const isMismatchCountVisible = isShiftDaysLoaded && !isDateRangeOpen && !isDateRangeInactive
    const { sendingReminder } = this.state
    const reminderButtonLabel = sendingReminder ? 'Reminder Sent' : 'Send Reminder'
    const isSmallViewport = window.innerWidth < 1400
    const reminderButtonSize = isSmallViewport ? 's' : 'auto'

    return (
      <div className="row align-middle float-right">
        {isDateRangeOpen && (
          <>
            <div className="cornflowerblue big black pv0 p10">
              {complitedUnavailabilityRequestsCount} / {staffUnavailabilityRequestsNumber}
            </div>
            <aside className="float-right">
              <sh-button
                id="reminder-button"
                disabled={sendingReminder ? true : undefined}
                onClick={this.sendReminderClick}
                color="tertiary"
                label={reminderButtonLabel}
                size={reminderButtonSize}
              />
              <sh-tooltip
                class="below-1400"
                label={capitalize(reminderButtonLabel)}
                target="reminder-button"
                placement="bottom"
                variation="short"
              />
            </aside>
          </>
        )}

        {isMismatchCountVisible && <MismatchCount {...mismatchCountProps} />}

        <ScheduleWorkflow {...scheduleWorkflowProps} />

        {mode === 'full' && !isEquipmentOrSubspecialityView && (
          <div className="flex-end-align float-right bl1 p10 pv0">
            <button className="_print" id="print-btn" onClick={this.showPrintModal}>
              <i className="icon-print casper" />
            </button>
            <sh-tooltip label="Print calendar" target="print-btn" placement="bottom" variation="short" />
          </div>
        )}
      </div>
    )
  }

  showPrintModal = () => {
    navigate.from.Calendar.to.PrintPDF()
  }
}
