import getEndTimeForShift from './getEndTimeForShift'

export default function getOperationalDetailsForShift(shift) {
  const { operationalDetails, startTime, allowableGap, targetCover } = shift

  for (const day in operationalDetails) {
    if (!operationalDetails[day].operationalDetail) {
      operationalDetails[day].operationalDetail = {
        startTime,
        endTime: getEndTimeForShift(shift),
        allowableGap,
        targetCover
      }
    }
  }

  return operationalDetails
}
