import './WeeklyViewEventBar.scss'
import { SpillProofDiv } from 'Common/components'
import { Avatar } from 'Manager/components'
import classNames from 'classnames'
import { getRemoteStaffData } from 'utils/getRemoteStaffData'
import { remoteServiceUserIds } from 'utils/remoteServiceUserIds'

function getStyle(isTimeOff, isOnCall) {
  if (isTimeOff) {
    return {
      backgroundColor: '#fefffc',
      color: 'rgb(57 59 63)',
      backgroundImage:
        'linear-gradient(135deg, rgb(222, 223, 225) 25%, rgb(247, 248, 250) 25%, rgb(247, 248, 250) 50%, rgb(222, 223, 225) 50%, rgb(222, 223, 225) 75%, rgb(247, 248, 250) 75%, rgb(247, 248, 250) 100%)',
      backgroundSize: ' 6px 6px',
      fontSize: '11px'
    }
  }
  if (isOnCall) {
    return {
      backgroundColor: '#eff3f9',
      color: 'rgb(57 59 63)',
      border: '2px solid #c3ccd5',
      fontSize: '11px'
    }
  }
  return {
    backgroundColor: '#9ab2c9',
    color: '#fefffc',
    fontSize: '11px'
  }
}

function getIsAssignmentIcon(isCancelled, assignmentType) {
  let icon = ''

  icon = 'icon-dot'
  if (isCancelled) {
    icon = 'svg-icon new-icon-cancelled-shift'
  }

  const eventType = assignmentType && assignmentType !== 'shift' ? assignmentType : 'shift'

  if (eventType === 'equipment') {
    icon = {
      iconImg: 'patient-scan',
      color: 'rgba(255, 255, 255, 1)'
    }
  } else if (eventType === 'subspeciality') {
    icon = {
      iconImg: 'patient-open-tasks',
      color: 'rgba(255, 255, 255, 1)'
    }
  }

  return icon
}

function getTypeIcon(cellStaffEvent, indicators) {
  const isAssignment = !!cellStaffEvent.get('isAssignment')
  const isMeeting = cellStaffEvent.get('isMeeting')
  const isCancelled = !!cellStaffEvent.get('isCancelled')
  const isOnCall = cellStaffEvent.get('isOnCall')
  const isPTO = !!cellStaffEvent.get('isPTO')
  const isUnavailable = cellStaffEvent.get('isUnavailable')
  const isPartialShiftEvent = !!cellStaffEvent.get('isPartialShiftEvent')
  const assignmentType = cellStaffEvent.get('assignmentAttributes')?.get('type')

  let icon = ''
  if (isAssignment) {
    icon = getIsAssignmentIcon(isCancelled, assignmentType)
  }

  if (isMeeting) {
    if (isPartialShiftEvent) {
      icon = 'new-icon-event-white'
    } else {
      icon = 'new-icon-event-black'
    }
  }

  if (isOnCall && indicators.get('onCall')) {
    icon = isCancelled ? 'svg-icon new-icon-cancelled-shift' : 'icon-indicator-call'
  }
  if (isPTO) {
    icon = 'Icons icon-Money-Sign'
  }
  if (isUnavailable && indicators.get('unavailable')) {
    icon = 'icon-close'
  }
  return icon
}

function isLocked(cell, indicators) {
  return (
    indicators.get('locks') &&
    !cell.get('isRequestedToWork') &&
    (cell.get('isMandatoryToWork') || cell.get('isTimeOffNotAllowed'))
  )
}

function getSetIcons(cell, cellStaffEvent, indicators, isTimeOff) {
  const isRequestedToWork = indicators.get('available') && cell.get('isRequestedToWork')
  const isPTO = cellStaffEvent.get('isPTO')
  const isInfo = indicators.get('info') && cellStaffEvent.get('isExpertiseMismatchEvent')
  const isLock = !isTimeOff && isLocked(cell, indicators)
  let iconsSet = []

  if (isLock && !isPTO) {
    iconsSet.push('icon-recurring')
  }

  if (isRequestedToWork) {
    iconsSet.push('icon-indicator-checkmark')
  }

  if (cell.get('isRequestedDayOff') && indicators.get('unavailable')) {
    iconsSet.push('time-off-icon')
  }

  if (isInfo) {
    iconsSet.push('icon-indicator-info')
  }

  if (cellStaffEvent.get('hasNotes') && indicators.get('noteOrExplanation')) {
    iconsSet.push('icon-indicator-note')
  }
  return iconsSet
}

function WeeklyViewEventBar({
  cell,
  cellStaffEvent,
  indicators,
  cellShiftData,
  facilityUsersMap,
  otherStaffMap,
  timeService
}) {
  let shiftName = cellShiftData?.get('name') || 'Time Off'

  const isPTO = cellStaffEvent.get('isPTO')
  const isUnavailable = cellStaffEvent.get('isUnavailable')
  const isTimeOff = isPTO || isUnavailable
  if (isPTO) {
    shiftName = 'Paid Time Off'
  } else if (isUnavailable) {
    shiftName = 'Request Off'
  }

  const isOnCall = cellStaffEvent.get('isOnCall')
  const shiftTime = cellStaffEvent?.get('shiftTime')
  const icon = getTypeIcon(cellStaffEvent, indicators)
  const setIconDetailed = getSetIcons(cell, cellStaffEvent, indicators, isTimeOff)
  let staff = facilityUsersMap?.get(cell.get('staff')) || otherStaffMap?.get(cell.get('staff'))
  const isRemoteStaff = remoteServiceUserIds.includes(cell.get('staff'))
  let remoteStaffAvatar
  if (!staff && isRemoteStaff) {
    const { staffProfile, avatar } = getRemoteStaffData(cell)
    staff = staffProfile
    remoteStaffAvatar = avatar
  }

  let avatarCellContent = null
  if (cell.get('isAvatarCell')) {
    const staffEvent = cell?.get('staffEvents')
    const shiftStartsAt = timeService._timeMoment(staffEvent?.getIn([0, 'startsAt'])).format('HH:mm')
    const shiftEndsAt = timeService._timeMoment(staffEvent?.getIn([0, 'endsAt'])).format('HH:mm')
    const staffFirstName = staff?.getIn(['facilityProfile', 'firstName'])
    const staffLastName = staff?.getIn(['facilityProfile', 'lastName'])
    avatarCellContent = (
      <>
        <div className="cursor-default">
          <div className="week-view-shiftView">
            <div className="p5 week-view-shift-icon">
              {staff ? (
                <Avatar profile={staff.get('profile')} extendedClass="hx-avatar-cell">
                  {remoteStaffAvatar}
                </Avatar>
              ) : (
                ' '
              )}
            </div>
          </div>
        </div>
        <div className="event-bar-shiftview">
          <div className="event-bar-staffFullName">
            {staffFirstName} &nbsp; {staffLastName}
          </div>
          <div className="shiftView-shiftRange">
            {shiftStartsAt} - {shiftEndsAt}
          </div>
        </div>
      </>
    )
  }

  const { iconImg, color } = icon

  const nonAvatarCellContent = (
    <>
      <div className="cursor-default">
        <span className="p5 week-view-shift-icon">
          {iconImg ? <sh-icon color={color} icon={iconImg} size="s" /> : <i className={icon}></i>}
        </span>
      </div>
      <div className="event-bar-shift-name">
        <span className="pr5">{shiftName}</span>
      </div>
      <div className="cursor-default event-bar-center">
        <span>{shiftTime}</span>
      </div>
      <span className="fullview-cell-icons disabled-pointer pl5  event-bar-right">
        {setIconDetailed.map((icon) => {
          let additionalStyle = {}
          let partialShiftEventInfoClassName = ''
          if (icon === 'icon-indicator-info') {
            const isPartialShiftEvent = cellStaffEvent.get('isPartialShiftEvent')
            partialShiftEventInfoClassName = isPartialShiftEvent && 'icon-info-partial-shift'
          }
          return (
            <i
              key={`${icon}-key-icon`}
              className={`${icon} ${partialShiftEventInfoClassName}`}
              style={{ marginLeft: 5, ...additionalStyle }}
            />
          )
        })}
      </span>
    </>
  )

  return (
    <>
      <div
        className={classNames('weekly-view-event-bar-main', {
          isAvatarCell: cell.get('isAvatarCell')
        })}
      >
        <div className="weekly-view-event-bar" style={getStyle(isTimeOff, isOnCall)}>
          <SpillProofDiv className="weekly-view-spillproof-bar">
            {cell.get('isAvatarCell') ? avatarCellContent : nonAvatarCellContent}
          </SpillProofDiv>
        </div>
      </div>
    </>
  )
}

export default WeeklyViewEventBar
