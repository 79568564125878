import getEndTimeForShift from './getEndTimeForShift'

export function getDefaultDetailsForShift(shift) {
  return {
    targetCover: {
      min: shift.targetCover.min,
      max: shift.targetCover.max
    },
    startTime: shift.startTime,
    endTime: getEndTimeForShift(shift),
    allowableGap: shift.allowableGap
  }
}
