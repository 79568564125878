import { PureComponent, createRef } from 'react'
import { Map } from 'immutable'
import { getClassName, getLeftColumn, getMiddleColumn } from './utils'
import { sideGlyphMap } from 'Manager/components/CellIconMap'
import './Cell.scss'
import { Avatar } from 'Manager/components'
import WeeklyViewEventBar from './WeeklyViewEventBar'
import { isNil } from 'lodash'
import { getRemoteStaffData } from 'utils/getRemoteStaffData'
import { remoteServiceUserIds } from 'utils/remoteServiceUserIds'

export default class Cell extends PureComponent {
  ref = createRef()

  componentDidUpdate(prevProps) {
    const { showPopup, cell, isMultipleCellsSelected } = this.props
    const { cell: prevCell, isMultipleCellsSelected: prevIsMultipleCellsSelected } = prevProps

    const isLastSelectedCell = cell.get('isLastSelectedCell')
    const prevIsLastSelectedCell = prevCell?.get('isLastSelectedCell')
    const isCellSelectionChanged = isLastSelectedCell !== prevIsLastSelectedCell
    const isCellsSelectionChanged = isMultipleCellsSelected !== prevIsMultipleCellsSelected
    const isSelectionChanged = isCellsSelectionChanged || isCellSelectionChanged

    const view = cell.get('isAvatarCell') ? 'shiftView' : 'staffView'
    if (isSelectionChanged && isLastSelectedCell) {
      showPopup(this.ref.current, view)
    }
  }

  render() {
    const {
      cell = Map(),
      cellIndex,
      day = {},
      indicators,
      facilityUsersMap,
      otherStaffMap,
      facilityShiftsMap,
      isPrintView,
      notes,
      rowStyle,
      activeDateRange,
      mode,
      timeService
    } = this.props
    const cellStaffEvent = cell.get('staffEvents')?.get(0) || Map()
    const identityHash = cellStaffEvent.get('identityHash') || 'empty'
    const isWeeklyView = mode === 'week'
    const unitId = activeDateRange.get('unitId')
    let content
    if (isWeeklyView) {
      content = getWeeklyViewContent(cell, indicators, facilityShiftsMap, facilityUsersMap, otherStaffMap, timeService)
    } else {
      content = getContent(
        cell,
        facilityUsersMap,
        otherStaffMap,
        facilityShiftsMap,
        isPrintView,
        indicators,
        notes,
        unitId
      )
    }

    const isCellDisabled = isNil(cellIndex)
    const className = getClassName(cell, day, isCellDisabled, activeDateRange, isWeeklyView)

    const isProcessing = cell.get('isProcessing')

    return (
      <div
        tabIndex={0}
        data-cell-index={cellIndex}
        data-cell-identity-hash={identityHash}
        className={className}
        ref={this.ref}
        style={{ height: `${rowStyle?.height}px` }}
      >
        {isProcessing ? <i className="loading spindle" /> : content}
      </div>
    )
  }
}

function getWeeklyViewContent(cell, indicators, facilityShiftsMap, facilityUsersMap, otherStaffMap, timeService) {
  const staffEvents = cell?.get('staffEvents') || Map()

  if (cell.size === 0 || cell.get('isProcessing')) {
    return null
  }

  const isProcessing = cell.get('isProcessing')
  if (isProcessing) {
    return null
  }
  return (
    <>
      {staffEvents?.map((staffEvent) => (
        <WeeklyViewEventBar
          cell={cell}
          key={staffEvent.get('id')}
          cellStaffEvent={staffEvent}
          indicators={indicators}
          cellShiftData={facilityShiftsMap?.get(staffEvent?.get('shiftId'))}
          facilityUsersMap={facilityUsersMap}
          otherStaffMap={otherStaffMap}
          timeService={timeService}
        />
      ))}
    </>
  )
}

function getContent(
  cell,
  facilityUsersMap,
  otherStaffMap,
  facilityShiftsMap,
  isPrintView,
  indicators = Map({}),
  notes = Map(),
  unitId
) {
  if (cell.get('isAvatarCell')) {
    let staff = facilityUsersMap?.get(cell.get('staff')) || otherStaffMap?.get(cell.get('staff'))

    const isRemoteStaff = remoteServiceUserIds.includes(cell.get('staff'))
    let remoteStaffAvatar
    if (!staff && isRemoteStaff) {
      const { staffProfile, avatar } = getRemoteStaffData(cell)
      staff = staffProfile
      remoteStaffAvatar = avatar
    }
    return (
      <>
        {staff ? (
          <Avatar profile={staff.get('profile')} extendedClass="hx-avatar-cell">
            {remoteStaffAvatar}
          </Avatar>
        ) : (
          ' '
        )}
      </>
    )
  }

  if (cell.size === 0 || cell.get('isProcessing')) {
    return null
  }

  const leftColumn = getLeftColumn(cell, indicators, isPrintView)
  const middleColumn = getMiddleColumn(cell, facilityShiftsMap, isPrintView, notes, unitId)
  const rightColumn = getRightColumn(cell, indicators, isPrintView)

  return (
    <div style={{ display: 'flex' }}>
      {leftColumn}
      {middleColumn}
      {rightColumn}
    </div>
  )
}

function getRightColumn(cell, indicators, isPrintView = false) {
  if (isPrintView) {
    return <span></span>
  }

  let hasPartialShift = false
  cell?.get('staffEvents')?.forEach((staffEvent) => {
    hasPartialShift = hasPartialShift || staffEvent.get('isPartialShiftEvent')
    return !hasPartialShift
  })

  let linearGradient = ''
  let backgroundClip = ''
  let textFillColor = ''

  if (hasPartialShift) {
    linearGradient = '-webkit-linear-gradient(red 50%, #b9cae5 50%)'
    backgroundClip = 'text'
    textFillColor = 'transparent'
  }

  const rightIcons = getRightIcons(cell, indicators)

  return (
    <span
      style={{
        background: linearGradient,
        WebkitBackgroundClip: backgroundClip,
        WebkitTextFillColor: textFillColor
      }}
    >
      {rightIcons}
    </span>
  )
}

function getRightIcons(cell, indicators) {
  let hasExpertiseMismatch = false
  let hasPartialShift = false
  let hasNotes = false
  cell?.get('staffEvents')?.forEach((staffEvent) => {
    hasNotes = hasNotes || staffEvent.get('hasNotes')
    hasPartialShift = hasPartialShift || staffEvent.get('isPartialShiftEvent')
    hasExpertiseMismatch = hasExpertiseMismatch || staffEvent.get('isExpertiseMismatchEvent')
    return !(hasPartialShift && hasExpertiseMismatch && hasNotes)
  })
  const showExpertiseMismatch = indicators.get('info') && hasExpertiseMismatch
  let rightIcons = ''

  if (showExpertiseMismatch || hasPartialShift) {
    rightIcons = 'info'
  } else if (cell.get('isRequestedDayOff') && indicators.get('unavailable')) {
    rightIcons = 'timeoff'
  }

  if (hasNotes && indicators.get('noteOrExplanation')) {
    if (rightIcons) {
      rightIcons += '+'
    }
    rightIcons += 'note'
  }

  return sideGlyphMap[rightIcons || 'gap']
}
