import { Component } from 'react'
import CellHour from './CellHour'
import classNames from 'classnames'
import StaffEvent from './StaffEvent'
import { List, Map } from 'immutable'
import './StaffShiftDay.scss'
import { remoteServiceUserIds } from 'utils/remoteServiceUserIds'

export default class StaffShiftDay extends Component {
  onMouseOver = (event) => this.onCellEvent(event, 'onMouseOver')

  onMouseLeave = (event) => this.onCellEvent(event, 'onMouseLeave')

  onClick = (event) => this.onCellEvent(event, 'onClick')

  onCellEvent = (event, eventType) => {
    // instead of `e.target.dataset` because IE
    if (!event.target.hasAttribute('data-day-cell-index')) {
      return
    }
    event.preventDefault()

    const cellIndex = parseInt(event.target.getAttribute('data-cell-index'))
    const dayCellIndex = parseInt(event.target.getAttribute('data-day-cell-index'))
    const identityHash = event.target.getAttribute('data-cell-identity-hash')
    const isEventBarElement = event.target.getAttribute('data-is-event-bar-element')
    const isDayViewCell = event.target.getAttribute('data-is-cell')

    const { target, shiftKey, metaKey, ctrlKey } = event
    const eventMeta = {
      eventTarget: target,
      isShiftKeyPressed: shiftKey,
      isMetaKeyPressed: metaKey,
      isCtrlKeyPressed: ctrlKey
    }
    const meta = { eventMeta, identityHash, cellSubIndex: cellIndex }

    if (event.target.className.includes('staff-event')) {
      meta.isEventBar = true
      if (event.target.className.includes('shift-view')) {
        meta.isShiftViewEventBar = true
      }
    }

    if (isEventBarElement) {
      meta.isEventBar = true
    }
    if (isDayViewCell) {
      meta.isCell = true
    }

    this.props.onCellEvent(dayCellIndex, eventType, meta)
  }

  isProcessing = (cell, roleIndex, shiftIndex, staffIndex, eventDay) => {
    const { cellsPathsInProcessing } = this.props
    const cellKey = `${roleIndex}|${shiftIndex}|${staffIndex}|${eventDay}`
    const isProcessing = cellsPathsInProcessing?.contains(cellKey)
    if (isProcessing) {
      cell = cell.set('isProcessing', isProcessing)
    }
    return cell
  }

  getSelectedCells = (selection) => {
    return selection ? selection.get('cells') : List()
  }

  isSelectedCell = (cell, selectedCell) => {
    if (selectedCell) {
      cell = cell.set('isSelected', true)
    }
    return cell
  }

  render() {
    const { staff, updateCellTooltip, staffPath, shift, indicators, dayViewFilters, dayIndex } = this.props
    const cells = staff.get('cells')
    const hourCells = staff.get('hourCells')

    const isShiftLoading = staff.get('isShiftLoading')
    const width = '100%'
    const rangeTime = shift.get('range')
    const { firstHour, lastHour } = this._getFirstLastHoursFromRange(rangeTime)

    return (
      <section
        className={classNames('row bl1 bt1 col-10 offcanvas-push-body', {
          loading: isShiftLoading
        })}
        onClick={this.onClick}
        onMouseOver={this.onMouseOver}
        onMouseLeave={this.onMouseLeave}
      >
        {hourCells.map((hour, hourIndex) => (
          <div key={hourIndex} className="br1 _weekCells bg-white _full" style={{ width }}>
            {this.renderCell(cells, hourIndex, hour, updateCellTooltip, staffPath, firstHour, lastHour, dayIndex)}
          </div>
        ))}
        {!this.isArrayEmpty(hourCells) &&
          this.renderStaffRowWithEvents(cells, dayIndex, shift, indicators, dayViewFilters, firstHour, lastHour)}
      </section>
    )
  }

  showStaffEvents = (eventBars, days, dayIndex) => {
    const { timeService } = this.props
    return (
      days.includes(dayIndex) ||
      (days.includes(dayIndex - 1) &&
        !timeService.timeMoment(eventBars[0].endsAt).isSame(timeService.timeMoment(eventBars[0].endsAt).startOf('day')))
    )
  }

  renderStaffRowWithEvents = (cells, dayIndex, shift, indicators, dayViewFilters) => {
    const {
      selection,
      showPopup,
      unitShiftsMap,
      facilityShiftsMap,
      activeDateRange,
      notes,
      staffManagersMap,
      updateEvent,
      selectSingleCell,
      timeService,
      staff
    } = this.props

    const eventBars = staff.get('eventBars')
    if (!eventBars || eventBars.size === 0) {
      return null
    }

    const days = eventBars.map((bar) => bar.dayIndex).sort()
    const shiftIndex = staff.get('shiftIndex')
    const staffIndex = staff.get('staffIndex')
    const roleIndex = staff.get('roleIndex')

    const getUpdatedEventBars = getEventBarsForStaffView(timeService)

    const processCell = (cell, roleIndex, shiftIndex, staffIndex, eventDay) => {
      cell = this.isProcessing(cell, roleIndex, shiftIndex, staffIndex, eventDay)
      const selectedCells = this.getSelectedCells(selection)
      const selectedCell = selectedCells.get(eventDay)
      return this.isSelectedCell(cell, selectedCell)
    }

    const getStaffEventProps = (cell, eventBar, eventDay, isShiftViewBar, resizeEnabled) => ({
      showPopup,
      cell,
      eventBar,
      shift,
      step: 15,
      indicators,
      dayViewFilters,
      unitShiftsMap,
      facilityShiftsMap,
      staffManagersMap,
      notes,
      timeService,
      dayIndex: eventDay,
      selectSingleCell,
      updateEvent,
      isShiftViewBar,
      resizeEnabled,
      facilityUsersMap: activeDateRange.get('facilityUsersMap')
    })

    return (
      <>
        {days.map((eventDay, index) => {
          let cell = this._getCellForStaffOrShiftView(staff, cells, eventDay)
          if (!cell) {
            return null
          }
          const eventBar = eventBars[index]
          if (eventDay === dayIndex - 1) {
            const updatedEventBars = getUpdatedEventBars(eventBars, index)
            if (!updatedEventBars) {
              return null
            }
          }
          if (!eventBar) {
            return null
          }
          cell = processCell(cell, roleIndex, shiftIndex, staffIndex, eventDay)
          const isRemoteStaff = remoteServiceUserIds.includes(cell.get('staff'))
          const resizeEnabled = !isRemoteStaff
          const isShiftViewBar = staff.get('isShiftView')
          const staffEventProps = getStaffEventProps(cell, eventBar, eventDay, isShiftViewBar, resizeEnabled)
          if (this.showStaffEvents(eventBars, days, dayIndex)) {
            return <StaffEvent key={`event-bar-${eventBar.id}-${eventDay}`} {...staffEventProps} />
          }
        })}
      </>
    )
  }

  _getCellForStaffOrShiftView = (staff, cells, eventDay) => {
    return staff.get('isShiftView') ? cells.get(0) : cells.get(eventDay)
  }

  renderCell(cells, hourIndex, hourCell, updateCellTooltip, staffPath, firstHour, lastHour, dayIndex) {
    const { showPopup, staff, cellsPathsInProcessing, shiftTime, indicators } = this.props
    let cell = this._getCellForStaffOrShiftView(staff, cells, dayIndex) || Map()

    const roleIndex = staff.get('roleIndex')
    const shiftIndex = staff.get('shiftIndex')
    const staffIndex = staff.get('staffIndex')
    const isFirstHour = firstHour === hourIndex
    const isLastHour = lastHour === hourIndex

    const cellKey = `${roleIndex}|${shiftIndex}|${staffIndex}|${dayIndex}`
    const isProcessing = cellsPathsInProcessing?.contains(cellKey)

    if (isProcessing) {
      cell = cell.set('isProcessing', isProcessing)
    }
    const { selection } = this.props
    const selectedCells = selection ? selection.get('cells') : List()
    const selectedCell = selectedCells.get(dayIndex)
    const previousSelectedCell = selectedCells.get(dayIndex - 1)
    let isCellSelected = false
    if (selectedCell) {
      const { cellSubIndex } = selectedCell
      isCellSelected = cellSubIndex === hourIndex

      cell = cell.set('isSelected', true)
    } else if (previousSelectedCell) {
      const prevCell = this._getCellForStaffOrShiftView(staff, cells, dayIndex - 1) || Map()
      const { cellSubIndex } = previousSelectedCell
      isCellSelected = cellSubIndex === hourIndex

      cell = prevCell.set('isSelected', true)
    }

    const isMultipleCellsSelected = selection && selection.get('cells').size > 1

    const cellProps = {
      cell,
      hourCell,
      cellIndex: hourIndex,
      showPopup,
      indicators,
      isMultipleCellsSelected,
      updateCellTooltip,
      staffPath,
      shiftTime,
      isFirstHour,
      isLastHour,
      dayIndex,
      isCellSelected
    }

    return <CellHour key={hourIndex} {...cellProps} />
  }

  getEventProps(eventBars, prevDayIndex, dayIndex) {
    const currEventBar = eventBars.find((bar) => bar.dayIndex === dayIndex)
    const prevEventBar = eventBars.find((bar) => bar.dayIndex === prevDayIndex)
    return {
      [prevDayIndex]: {
        minHour: 0,
        maxHour: currEventBar.startsAtHours + (1 - currEventBar.firstHour)
      },
      [dayIndex]: {
        minHour: prevEventBar.endsAtHours + prevEventBar.lastHour,
        maxHour: 23
      }
    }
  }

  isArrayEmpty(arrayForCheck) {
    return arrayForCheck.every((item) => item.value === 0)
  }

  _getFirstLastHoursFromRange = (dateRange) => {
    const arrayRange = dateRange?.split('-')
    let firstHour
    let lastHour
    if (arrayRange?.length) {
      const firstHourArray = arrayRange[0].split(':')
      firstHour = firstHourArray?.[0]
      const lastHourArray = arrayRange[1].split(':')
      lastHour = lastHourArray?.[0]
    }
    return { firstHour: +firstHour, lastHour: +lastHour - 1 }
  }
}

function getEventBarsForStaffView(timeService) {
  return (eventBars, index) => {
    if (
      !timeService
        .timeMoment(eventBars[index].endsAt)
        .isSame(timeService.timeMoment(eventBars[index].endsAt).startOf('day'))
    ) {
      let updatedEventBars = eventBars[index]
      updatedEventBars.firstHour = 1
      eventBars[index] = { ...updatedEventBars }
    } else {
      return null
    }
  }
}
