import { PureComponent } from 'react'
import { ImmutableSelect } from 'Manager/components'
import { pick } from 'lodash'
import StaffEventController from './StaffEventController'
import Notes from '../Notes'
import NotesController from './StaffEventNotesController'
import StaffEventTemplateSelector from './StaffEventTemplateSelector'
import classNames from 'classnames'
import { hasShiftEvent } from '../cellDetailsUtils'
import { List } from 'immutable'
import StaffSelector from './StaffSelector'
import { t } from 'i18n'

export default class ShiftForm extends PureComponent {
  constructor(props) {
    super(props)

    this.notesController = new NotesController(this)
    this.staffEventController = new StaffEventController(this)

    const { defaultState: staffEventState } = this.staffEventController
    const { defaultState: notesState } = this.notesController

    this.state = { ...staffEventState, ...notesState, selectedStaff: null }
  }

  async componentDidMount() {
    const { cell } = this.props
    this.notesController.loadNotes()
    await this.staffEventController.loadCellStaffEvents()
    if (cell.get('isAvatarCell') && cell.get('staff')) {
      const staff = this.getStaff(cell.get('staff'))
      const unitId = staff.getIn(['staffProfile', 'eligibleUnits'])?.get(0)?.get('homeUnitId')
      this.setState({ selectedStaff: staff, unitId })
    }
  }

  componentDidUpdate(prevProps) {
    const { cell } = this.props
    const { cell: prevCell } = prevProps

    const isCellChanged = cell !== prevCell
    if (isCellChanged) {
      this.notesController.loadNotes()
      this.staffEventController.loadCellStaffEvents()
    }
  }

  render() {
    // add support of cellSubIndex
    const { selectedParameters, eligibleUnits } = this.staffEventController
    if (!eligibleUnits || eligibleUnits.size === 0) {
      return null
    }
    const { cell, isProcessing, onCancel, isShiftFormShown } = this.props
    const { isNotesLoading, isStaffEventLoading } = this.state
    const isLoading = isNotesLoading || isStaffEventLoading
    const { unitId, eventTemplate } = selectedParameters

    const hasEvent = hasShiftEvent(cell)

    const isFormFilled = !!unitId && !!eventTemplate
    const isInputDisabled = isLoading || isProcessing
    const isActionDisabled =
      isLoading || isProcessing || !isFormFilled || (cell.get('isAvatarCell') && !this.state.selectedStaff)

    const className = classNames('add-shift bt1', { hide: !isShiftFormShown })

    const shiftFormTitle = hasEvent ? t('calendar.update_assignment_label') : t('calendar.assign_staff_label')

    return (
      <div className={className}>
        {cell.get('isAvatarCell') && <header className="p20 pb0 bold">{shiftFormTitle}</header>}
        {isLoading && this.renderSpinner()}
        {!isLoading && this.renderForm()}

        <footer className="p20">
          <button className="button compact secondary borderless ph0" onClick={onCancel} disabled={isInputDisabled}>
            Cancel
          </button>

          {hasEvent && (
            <>
              <button
                disabled={isActionDisabled}
                className="button float-right compact bg-skyblue _update-button"
                onClick={this.update}
              >
                Update
              </button>

              <span className="float-right">&nbsp;</span>

              <button
                className="button float-right compact primary _delete-button"
                onClick={this.delete}
                disabled={isInputDisabled}
              >
                Remove
              </button>
            </>
          )}

          {!hasEvent && (
            <button
              disabled={isActionDisabled}
              className="button float-right compact bg-skyblue _create-button"
              onClick={this.create}
            >
              Add
            </button>
          )}
        </footer>
      </div>
    )
  }

  renderSpinner() {
    return (
      <section className="p15">
        <div className="loader">
          <i className="loading spindle" />
        </div>
      </section>
    )
  }

  renderForm() {
    const { eligibleUnits, eventTemplates, selectedParameters, selectedEventTemplateId } = this.staffEventController
    const { unitId, eventTemplate } = selectedParameters
    const { cell } = this.props

    const unitSelectProps = {
      disabled: !unitId,
      options: eligibleUnits,
      value: unitId,
      onChange: this.onUnitChange
    }

    const eventTemplateSelectorProps = pick(
      {
        ...this.props,
        eventTemplates,
        eventTemplate,
        setEventTemplate: this.staffEventController.setEventTemplate,
        selectedEventTemplateId,
        loadNotes: () => this.notesController.loadNotes()
      },
      [
        'timeService',
        'day',
        'eventTemplates',
        'eventTemplate',
        'shiftsMap',
        'setEventTemplate',
        'selectedEventTemplateId',
        'loadNotes',
        'cell',
        'shiftId'
      ]
    )

    const { notes } = this.notesController
    const notesProps = { notes, notesController: this.notesController }

    const hasEvent = hasShiftEvent(cell)

    const unitStaffList = this.getUnitStaffList(unitId)
    return (
      <>
        <section className="p15 pt0">
          <div className="row pt20">
            <div className="form-item col col-3 text-right pr10">
              <label>UNIT</label>
            </div>
            <div className="form-item col col-9">
              <ImmutableSelect {...unitSelectProps} disabled={!cell.get('isAvatarCell')} />
            </div>
          </div>
          <StaffEventTemplateSelector {...eventTemplateSelectorProps} hasEvent={hasEvent} />
          {cell.get('isAvatarCell') && (
            <StaffSelector
              currentStaff={this.state.selectedStaff}
              staffList={unitStaffList}
              onSelect={(staff) => {
                this.setState({ selectedStaff: staff })
              }}
            />
          )}
        </section>

        <Notes {...notesProps} />
      </>
    )
  }

  onUnitChange = (e) => {
    const unitId = e.currentTarget.value
    this.staffEventController.unitId = unitId
    this.setState({ selectedStaff: null })
  }

  create = async () => {
    const { createStaffEventsForSelection, onCancel, getStaffEventDetails, cell } = this.props
    let eventParameters = this.staffEventController.getEventParameters()
    if (cell.get('isAvatarCell')) {
      eventParameters = eventParameters.set('addStaffToOnCallShift', true).set('staff', this.state.selectedStaff)
    }
    getStaffEventDetails?.(eventParameters)
    const { note } = this.notesController
    createStaffEventsForSelection(eventParameters, note)

    onCancel()
  }

  update = async () => {
    const { onCancel, cell } = this.props
    const { staffEvent, selectedStaff } = this.state
    let selectedEventParameters = this.staffEventController.getEventParameters()
    const selectedShiftId = selectedEventParameters.get('shiftId')

    const isStaffChanged = selectedStaff && selectedStaff.get('id') !== cell.get('staff')
    const isShiftChanged = staffEvent.some((item) => item.shiftId === selectedShiftId)
    isStaffChanged || !isShiftChanged ? this.changeEvent() : this.updateEvent()

    onCancel()
  }

  updateEvent = () => {
    const { staffEvent } = this.state
    const { updateEventForSelection } = this.props

    const eventParamaters = this.staffEventController.getEventParameters()
    const selectedShiftId = eventParamaters.get('shiftId')
    const selectedStaffEvent = staffEvent.find((item) => item.shiftId === selectedShiftId)
    const { type } = selectedStaffEvent || {}

    const { noteToAdd, notesToUpdate } = this.notesController

    return updateEventForSelection(eventParamaters, { type }, noteToAdd, notesToUpdate)
  }

  changeEvent = async () => {
    const { changeStaffEventForSelection, cell, getStaffEventDetails } = this.props
    const { selectedStaff } = this.state
    let eventParameters = this.staffEventController.getEventParameters()
    const { note } = this.notesController
    if (cell.get('isAvatarCell')) {
      eventParameters = eventParameters.set('addStaffToOnCallShift', true).set('staff', selectedStaff)
    }
    getStaffEventDetails?.(eventParameters)

    return changeStaffEventForSelection(eventParameters, note)
  }

  delete = async () => {
    const { deleteSelectedStaffEvents, onCancel, cell } = this.props
    const { selectedEventTemplateId } = this.staffEventController
    const cellStaffEvent = (cell.get('staffEvents') || [])
      ?.toJS()
      .filter((e) => selectedEventTemplateId?.includes(e.shiftId))
    const staffEventId = cellStaffEvent[0]?.id

    deleteSelectedStaffEvents(staffEventId)
    onCancel()
  }

  getStaff = (staffId) => {
    return this.getStaffList().find((staff) => staff?.get('id') === staffId)
  }

  getUnitStaffList = (unitId) => {
    const staffList = this.getStaffList().sort((a, b) => a.get('fullName')?.localeCompare(b.get('fullName')))
    return staffList.filter((staff) => {
      return staff?.getIn(['staffProfile', 'eligibleUnits'])?.some((unit) => unit.get('homeUnitId') === unitId)
    })
  }
  getStaffList = () => {
    const { getViewModel } = this.props
    let staffList = List([])
    const shifts = getViewModel({}, false, true).filter((row) => row.get('kind') === 'shift')
    shifts.forEach((e) => {
      const shiftStaffList = e.get('staff')
      if (shiftStaffList?.size) {
        shiftStaffList?.forEach((staff) => {
          if (staff.get('fullName')) {
            staffList = [...staffList, staff]
          }
        })
      }
    })
    return staffList
  }
}
