import { fromJS } from 'immutable'
import { getRemoteWorkflowTypeIcon } from './getRemoteWorkflowTypeIcon'

export const getRemoteStaffData = (cell) => {
  const remoteWorkflowtype = cell
    .get('staffEvents')
    ?.get(0)
    ?.get('remoteStaffRequestAttributes')
    ?.get('remoteWorkflowType')

  let staffProfile, avatar

  // eslint-disable-next-line sonarjs/no-small-switch
  switch (remoteWorkflowtype) {
    case 'wescan':
      staffProfile = fromJS({
        profile: {
          firstName: 'WeScan',
          lastName: 'Service',
          avatarUrl: ''
        },
        facilityProfile: {
          firstName: 'WeScan',
          lastName: 'Service',
          avatarUrl: ''
        }
      })

      avatar = getRemoteWorkflowTypeIcon(remoteWorkflowtype)
      break

    default:
      staffProfile = fromJS({})
      avatar = null
  }
  return { staffProfile, avatar }
}
