import { updateShiftDayMetadataMutation } from 'stores/openShiftsStore/Mutations'

function ShiftRequirements() {
  let gqlClient = null
  const actions = {
    updateShiftDayMetadata
  }

  return { initialize, actions }

  function initialize(context) {
    ;({ gqlClient } = context)
  }

  async function updateShiftDayMetadata(parameters) {
    const { shiftDayId, ...shiftDayMetaData } = parameters
    const params = {
      id: shiftDayId,
      parameters: shiftDayMetaData
    }
    return gqlClient.mutate(updateShiftDayMetadataMutation, params)
  }
}

export default ShiftRequirements()
